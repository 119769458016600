import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  min-height: 10rem;
  max-height: 20rem;
  min-width: 2rem;
  max-width: 100%;
  background: var(--bgColor-1);
  border-radius: var(--borderRadius-m);
  box-shadow: var(--shadow-3);

  @media (max-height: 800px) {
    min-height: 8rem;
  }

  &:hover {
    transform: translateY(-0.5rem);
    transition: transform 0.3s;
    background: var(--primary-25);
  }

  .infoWrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  .mainTitleWrapper {
    width: 100%;
    height: calc(35% - 2px);
    display: flex;
    align-items: center;
  }
  .mainTitle {
    text-align: start;
    padding: 0.4rem;
  }

  .mainInfoWrapper {
    width: 100%;
    height: calc(65% - 2px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainInfo {
    font-weight: 600;
  }

  .imgWrapper {
    width: 30%;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    width: 80%;
    height: 70%;
  }
`;

const Separator = styled.div`
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, rgba(22, 60, 67, 0.2) 0%, rgba(240, 249, 251, 0.2) 100%);
`;

type CardInfoProps = {
  title: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  mainInfo: string;
};

export default function CardsHomeInfo({ title, Icon, mainInfo }: CardInfoProps) {
  return (
    <CardContainer>
      <div className="infoWrapper">
        <div className="mainTitleWrapper">
          <h5 className="mainTitle">{title}</h5>
        </div>
        <Separator />
        <div className="mainInfoWrapper">
          <h2 className="mainInfo">{mainInfo}</h2>
        </div>
      </div>
      <div className="imgWrapper">
        <Icon className="img" />
      </div>
    </CardContainer>
  );
}
