import axios from 'axios';
import { getToken } from './auth';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    'Content-type': 'application/json'
  }
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      toast.error('Sessão expirada, faça login novamente');
    }
    if (error.response.status === 500) {
      toast.error('Você não tem permissão para acessar essa página');
    }
    return Promise.reject(error.response);
  }
);

export default api;
