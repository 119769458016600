import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Select,
  MenuItem,
  Stack,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  Box,
  LinearProgress,
  Tabs,
  Tab
} from '@mui/material';

import { IUser, blankUser } from '../../models/User';
import { toast } from 'react-toastify';
import { validateEmail, validateRequired } from '../../utils/validations';
import { getModelFromField, maskPhone } from '../../utils/MyLib';
import InputMask from 'react-input-mask';
import { ICompany } from '../../models/Company';
import { allStatesNames } from '../../models/States';
import { getAuthUser } from '../../services/auth';
import { ZipService } from '../../services/ZipService';
import { BigTextField, MediumTextField, SmallTextField } from '../../components/TextFields';
import { AvatarUpload } from '../../components/AvatarUpload';
import { ImgUploadService } from '../../services/ImgUploadService';
import styled from 'styled-components';
import { blankAddress } from '../../models/Address';
// import { document } from 'document-cnpj-validator';
import { CardTypeUser } from '../../components/CardTypeUser';
import { UserService } from '../../services/UserService';
import { baseImg } from '../../App';
import { ConfirmModal } from '../../components/ConfirmModal';
import { useNavigate, useParams } from 'react-router-dom';
import PageStructure from '../../components/PageStructure';
import { PageContainer } from '../../components/GlobalStyleds';
import CompanyService from '../../services/CompanyService';
import { width } from '@mui/system';

const Separator = styled.hr`
  border: 1px solid grey; /* Cor da linha branca */
`;

export default function UserForm() {
  const [user, setUser] = useState<IUser>(blankUser);
  const [errors, setErrors] = useState<any>({});
  const [modal, setModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [possibleCompanies, setPossibleCompanies] = useState<ICompany[]>([]);
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir a rota?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [tab, setTab] = useState(0);

  const navigate = useNavigate();

  let { id } = useParams();
  let address = user.address;
  let wallet = user.wallet || { balance: 0 };
  let role = user.role;
  let imagem = user.img ? baseImg + user.img : '';
  let fileImage: File = new File([], '');
  let docMask = user.type === 0 ? '999.999.999-99' : '99.999.999/9999-99';

  //Get all collectors
  async function fetchData() {
    try {
      let user = blankUser;
      if (Number(id)) {
        user = await UserService.getById(Number(id));
      } else {
        user = blankUser;
        user = { ...blankUser, company_id: getAuthUser().company?.id || 0 };
      }
      setUser(user);
      CompanyService.getPermissionsCompanies().then((result) => {
        setPossibleCompanies(result);
      });

      // Trocar pelo metodo que busque no servidor apenas as coletas daquela rota
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);
  function handleDeleteConfirmation(user: IUser) {
    setConfirmMessage('Deseja excluir o usuário ' + user.name + ' ?');
    setShowConfirmationModal(true);
  }

  async function handleDelete() {
    setShowConfirmationModal(false);
    setLoading(true);
    if (await UserService.delete(user.id)) {
      setLoading(false);
      toast.success('Usuário apagado com sucesso');
      user.id = -1;
      navigate('/users');
    }
  }

  function validateErrors(user: IUser) {
    let errors: any = {};
    if (!validateRequired(user.name)) errors.name = 'Obrigatorio';
    if (!validateEmail(user.email)) errors.email = 'Email invalido';
    if (!validateRequired(user.role.name)) errors.role = 'Perfil Obrigatorio';
    if (!validateRequired(user.phone_number)) errors.phone_number = 'Telefone Obrigatorio';
    if (!validateRequired(user.address.zip_code)) errors.zip_code = 'Cep Obrigatorio';
    if (!validateRequired(user.address.street)) errors.street = 'Rua Obrigatorio';
    if (!validateRequired(user.address.neighborhood)) errors.neighborhood = 'Bairro Obrigatorio';
    if (!validateRequired(user.address.city)) errors.city = 'Cidade Obrigatoria';
    if (!validateRequired(user.address.state)) errors.state = 'Estado Obrigatorio';
    // if (!validateRequired(user.document)) errors.document = 'CPF/CNPJ Obrigatorio';
    if (['Operador', 'Admin'].includes(user.role.name) && !user.company_id) {
      toast.error('Perfil Operador e Admin precisam de uma empresa vinculada');
      errors.company_id = 'Empresa Obrigatorio';
    }
    // if (!document.isValid(user.document)) errors.document = 'document invalido';
    return errors;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const errors = validateErrors(user);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveUser(user);
      navigate('/users');
    }
  };
  function saveImage(user: IUser, fileImage: File): void {
    if (fileImage.size > 0) {
      if (fileImage.size <= 1000000) {
        ImgUploadService.upload('users', user.id, fileImage);
      } else {
        toast.error('Imagem muito grande, tamanho máximo de 1mb');
      }
    }
  }

  async function saveUser(user: IUser) {
    if (user.id) {
      if (await UserService.update(user)) {
        saveImage(user, fileImage);
        toast.success('Usuário salvo com sucesso');
      }
    } else {
      user.password = user.document.replace(/\D/g, '');
      let newUser = await UserService.create(user);
      if (newUser.id > 0) {
        user.id = newUser.id;
        saveImage(user, fileImage);
        toast.success('Usuário criado com sucesso');
      }
    }
  }

  async function handleBlurZipCode(e: any) {
    let zipCode = e.target.value;
    let address = blankAddress;
    if (zipCode[9] !== '_' && !user.address.street) {
      address = await ZipService.getAddress(zipCode);
      if (address.street) {
        setUser({ ...user, address: { ...address, id: user.address.id } });
      } else {
        toast.error('Cep não encontrado');
      }
    }
  }
  //Get values on form inputs and put into the model user
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;

    switch (model) {
      case 'address':
        if (field === 'zip_code' && value[0] === '_') {
          setUser({ ...user, address: { ...blankAddress, id: user.address.id } });
        } else {
          address = { ...user.address, [field]: value };
          setUser({ ...user, address: address });
        }
        break;
      case 'wallet':
        wallet = { ...user.wallet, [field]: value };
        setUser({ ...user, wallet: wallet });
        break;
      case 'role':
        role = { ...user.role, [field]: value };
        setUser({ ...user, role: role });
        break;
      case 'user':
        if (field === 'phone_number') {
          value = maskPhone(value);
        }
        if (field === 'type') {
          value = parseInt(value);
          docMask = value === 0 ? '999.999.999-99' : '99.999.999/9999-99';
        }
        setUser({ ...user, [field]: value });
        break;
    }
  }
  //handle avatar upload
  async function handleAvatarUpload(files: any) {
    fileImage = files[0];
  }
  async function handleCardTypeUser(choice: string) {
    // role = { ...user.role, name: choice };
    user.role.name = choice;
    setUser(user);
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={modal} maxWidth="lg">
          <DialogTitle textAlign="center">REGISTRO DE USUÁRIOS</DialogTitle>
          <Separator />
          {loading && (
            <Box sx={{ width: '100%', alignContent: 'center' }}>
              <LinearProgress />
            </Box>
          )}
          <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Stack alignItems="center">
                    <AvatarUpload src={imagem} width={100} height={100} radius={50} handleUpload={handleAvatarUpload} />
                    <small>{user.email}</small>
                    <br />
                  </Stack>
                  <Stack>
                    <FormControl variant="standard" sx={{ maxWidth: 150, m: 1 }} size="small">
                      <InputLabel>Status</InputLabel>
                      <Select key="user.type" label="Status" name="user.active" value={user.active} onChange={handleChange} error={errors.active}>
                        <MenuItem key="-1" value={-1}>
                          Rejeitado
                        </MenuItem>
                        <MenuItem key="0" value={0}>
                          Solicitado
                        </MenuItem>
                        <MenuItem key="0" value={1}>
                          Ativo
                        </MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl variant="standard" sx={{ maxWidth: 150, m: 1 }} size="small">
                      <InputLabel>Tipo</InputLabel>
                      <Select key="user.type" label="Tipo" name="user.type" value={user.type} onChange={handleChange} error={errors.type}>
                        <MenuItem key="0" value={0}>
                          Pessoa Física
                        </MenuItem>
                        <MenuItem key="0" value={1}>
                          Pessoa Jurídica
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', paddingTop: '0!important' }}>
                  {!(user.role.name === 'Super Admin' && user.id) && <CardTypeUser value={user.role.name} name="role.name" onChange={handleCardTypeUser} />}
                </Grid>
                <Grid item xs={12} md={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', paddingTop: '0!important' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={tab}
                      onChange={(event: React.SyntheticEvent, newValue: number) => {
                        setTab(newValue);
                      }}
                      aria-label="basic tabs example">
                      <Tab label="INFORMAÇÕES DO USUÁRIO" {...a11yProps(0)} />
                      <Tab label="PLANOS E PAGAMENTOS" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                </Grid>
                <div hidden={tab !== 0}>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <Stack>
                        <BigTextField key="user.name" label="Nome" name="user.name" value={user.name} onChange={handleChange} error={errors.name} helperText={errors.name} />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Stack>
                        <InputMask mask={docMask} name="user.document" value={user.document} onChange={handleChange} maskChar=" ">
                          <BigTextField label="CPF/CNPJ" error={errors.document} helperText={errors.document} />
                        </InputMask>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Stack>
                        <MediumTextField
                          key="user.phone_number"
                          label="Telefone"
                          name="user.phone_number"
                          value={user.phone_number}
                          onChange={handleChange}
                          error={errors.phone_number}
                          helperText={errors.phone_number}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Stack>
                        <MediumTextField
                          key="user.job_title"
                          label="Cargo/Função"
                          name="user.job_title"
                          value={user.job_title}
                          onChange={handleChange}
                          error={errors.job_title}
                          helperText={errors.job_title}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Stack>
                        <BigTextField key="user.email" label="e-Mail" name="user.email" value={user.email} onChange={handleChange} error={errors.email} helperText={errors.email} />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack>
                        <FormControl sx={{ m: 1 }}>
                          <InputLabel>Empresa</InputLabel>
                          <Select key="user.company_id" label="Empresa" name="user.company_id" value={user.company_id} onChange={handleChange} error={errors.company_id}>
                            {getAuthUser().role.name === 'Super Admin' && (
                              <MenuItem key="0" value="0">
                                Nenhum vinculo a empresas
                              </MenuItem>
                            )}
                            {possibleCompanies.map((company) => (
                              <MenuItem key={company.id} value={company.id}>
                                {company.fantasy_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Grid>

                    {/* Endereço */}
                    <Grid item xs={12} md={1.5}>
                      <Stack>
                        <InputMask mask="99.999-999" name="address.zip_code" value={address.zip_code} onChange={handleChange} onBlur={handleBlurZipCode} maskChar="">
                          <SmallTextField label="CEP" error={errors.zip_code} helperText={errors.zip_code} sx={{ maxWidth: 200 }} />
                        </InputMask>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                      <Stack>
                        <BigTextField key="address.street" label="Rua" name="address.street" value={address.street} onChange={handleChange} error={errors.street} helperText={errors.street} />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={1.2}>
                      <Stack>
                        <SmallTextField
                          key="address.number"
                          label="Número"
                          name="address.number"
                          value={address.number}
                          onChange={handleChange}
                          error={errors.number}
                          helperText={errors.number}
                          sx={{ maxWidth: 200 }}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={2.8}>
                      <Stack>
                        <BigTextField
                          key="address.complement"
                          label="Complemento"
                          name="address.complement"
                          value={address.complement}
                          onChange={handleChange}
                          error={errors.complement}
                          helperText={errors.complement}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <Stack>
                        <MediumTextField
                          key="address.neighborhood"
                          label="Bairro"
                          name="address.neighborhood"
                          value={address.neighborhood}
                          onChange={handleChange}
                          error={errors.neighborhood}
                          helperText={errors.neighborhood}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={5}>
                      <Stack>
                        <MediumTextField key="address.city" label="Cidade" name="address.city" value={address.city} onChange={handleChange} error={errors.city} helperText={errors.city} />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <Stack>
                        <FormControl sx={{ maxWidth: 150, m: 1 }} margin="dense">
                          <InputLabel>Estado</InputLabel>
                          <Select key="address.state" label="Estado" name="address.state" value={address.state} onChange={handleChange} error={errors.address} placeholder="Estado">
                            {allStatesNames.map((state) => (
                              <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                {state.abbreviation}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
                <div hidden={tab !== 1}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <BigTextField key="user.name" label="Nome" name="user.name" value={user.name} onChange={handleChange} error={errors.name} helperText={errors.name} />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <MediumTextField key="wallet.balance" label="Saldo" name="wallet.balance" value={wallet.balance} onChange={handleChange} error={errors.balance} helperText={errors.balane} />
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button
              color="warning"
              disabled={user.id === 0}
              onClick={() => {
                handleDeleteConfirmation(user);
              }}>
              Apagar
            </Button>

            <Button
              onClick={() => {
                navigate('/users');
              }}>
              Voltar
            </Button>
            <Button color="secondary" onClick={handleSubmit} variant="contained">
              Salvar
            </Button>
          </DialogActions>
          <ConfirmModal title="Deleção de Usuários" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
