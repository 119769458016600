import React from 'react';
import styled, { css } from 'styled-components';

const DashboardContainer = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  background: ${(props) => props.color};
  border-bottom: 4px solid #ffffff;
  position: relative;
`;

const MainText = styled.h1`
  color: #ffffff;
`;

const SubText = styled.p`
  color: #ffffff;
  font-weight: 700;
`;

const Triangle = styled.div<{ color: string }>`
  width: 50px;
  height: 50px;
  background: ${(props) => props.color};
  border: 4px solid #ffffff;
  border-top: none;
  border-left: none;
  rotate: 45deg;
  position: absolute;
  bottom: -27.8px;
  z-index: 1;
`;
interface DashboardCardProps {
  weight?: number;
  subText?: string;
  color: string;
}

export default function DashboardCard({ weight, subText, color }: DashboardCardProps) {
  return (
    <DashboardContainer color={color}>
      <MainText>{weight}Kg</MainText>
      <SubText>{subText}</SubText>
      <Triangle color={color} />
    </DashboardContainer>
  );
}
