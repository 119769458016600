import './Autentication.css';
import logo from '../../assets/logo3.png';
import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Select,
  MenuItem,
  Stack,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  Box,
  LinearProgress,
  Container,
  TextField
} from '@mui/material';

import { IUser, blankUser } from '../../models/User';
import { toast } from 'react-toastify';
import { validateEmail, validateRequired } from '../../utils/validations';
import { getModelFromField, maskPhone } from '../../utils/MyLib';
import InputMask from 'react-input-mask';
import { ICompany } from '../../models/Company';
import { allStatesNames } from '../../models/States';
import { getAuthUser } from '../../services/auth';
import { ZipService } from '../../services/ZipService';
import { BigTextField, MediumTextField, SmallTextField } from '../../components/TextFields';
import { AvatarUpload } from '../../components/AvatarUpload';
import { ImgUploadService } from '../../services/ImgUploadService';
import styled from 'styled-components';
import { blankAddress } from '../../models/Address';
// import { document } from 'document-cnpj-validator';
import { CardTypeUser } from '../../components/CardTypeUser';
import { UserService } from '../../services/UserService';
import { baseImg } from '../../App';
import { ConfirmModal } from '../../components/ConfirmModal';
import { useNavigate, useParams } from 'react-router-dom';
import PageStructure from '../../components/PageStructure';
import { PageContainer, Separator } from '../../components/GlobalStyleds';
import CompanyService from '../../services/CompanyService';

export default function SignUp() {
  const [user, setUser] = useState<IUser>(blankUser);
  const [errors, setErrors] = useState<any>({});
  const [possibleCompanies, setPossibleCompanies] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  // const [docMask, setDocMask] = useState('999.999.999-99');
  const navigate = useNavigate();
  let fileImage: File = new File([], '');
  let address = user.address;
  let role = user.role;
  let imagem = user.img ? baseImg + user.img : '';
  let docMask = user.type === 0 ? '999.999.999-99' : '99.999.999/9999-99';

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);
  async function fetchData() {
    try {
      let user = blankUser;
      user.role.name = 'Gerador';
      setUser(user);
      CompanyService.getAllNames().then((result) => {
        setPossibleCompanies(result);
      });

      // Trocar pelo metodo que busque no servidor apenas as coletas daquela rota
    } catch (error) {
      console.log(error);
    }
  }

  function validateErrors(user: IUser) {
    let errors: any = {};
    if (!validateRequired(user.name)) errors.name = 'Obrigatorio';
    if (!validateEmail(user.email)) errors.email = 'Email invalido';
    if (!validateRequired(user.role.name)) errors.role = 'Perfil Obrigatorio';
    if (!validateRequired(user.phone_number)) errors.phone_number = 'Telefone Obrigatorio';
    if (!validateRequired(user.address.zip_code)) errors.zip_code = 'Cep Obrigatorio';
    if (!validateRequired(user.address.street)) errors.street = 'Rua Obrigatorio';
    if (!validateRequired(user.address.neighborhood)) errors.neighborhood = 'Bairro Obrigatorio';
    if (!validateRequired(user.address.city)) errors.city = 'Cidade Obrigatoria';
    if (!validateRequired(user.address.state)) errors.state = 'Estado Obrigatorio';
    if (!validateRequired(user.document)) errors.document = 'CPF/CNPJ Obrigatorio';
    if (['Operador', 'Admin'].includes(user.role.name) && !user.company_id) {
      toast.error('Perfil Operador e Admin precisam de uma empresa vinculada');
      errors.company_id = 'Empresa Obrigatorio';
    }
    if (!validateRequired(user.password)) errors.password = 'Senha Obrigatoria';
    if (user.password.length < 6) errors.password = 'Senha deve ter no minimo 6 caracteres';
    if (user.password !== user.password2) {
      errors.password2 = 'Senhas não conferem';
    }
    // if (!document.isValid(user.document)) errors.document = 'document invalido';
    return errors;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const errors = validateErrors(user);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveUser(user);
      navigate('/login');
    }
  };
  function saveImage(user: IUser, fileImage: File): void {
    if (fileImage.size > 0) {
      if (fileImage.size <= 1000000) {
        ImgUploadService.upload('users', user.id, fileImage);
      } else {
        toast.error('Imagem muito grande, tamanho máximo de 1mb');
      }
    }
  }

  async function saveUser(user: IUser) {
    if (user.id) {
      if (await UserService.update(user)) {
        saveImage(user, fileImage);
        toast.success('Usuário salvo com sucesso');
      }
    } else {
      let newUser = await UserService.create(user);
      // let newUser = user;
      if (newUser.id > 0) {
        user.id = newUser.id;
        saveImage(user, fileImage);
        toast.success('Usuário criado com sucesso');
      }
    }
  }
  async function handleBlurZipCode(e: any) {
    let zipCode = e.target.value;
    let address = blankAddress;
    if (zipCode[9] !== '_' && !user.address.street) {
      address = await ZipService.getAddress(zipCode);
      if (address.street) {
        setUser({ ...user, address: address });
      } else {
        toast.error('Cep não encontrado');
      }
    }
  }

  //Get values on form inputs and put into the model user
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;

    switch (model) {
      case 'address':
        address = { ...user.address, [field]: value };
        setUser({ ...user, address: address });
        break;
      case 'role':
        role = { ...user.role, [field]: value };
        setUser({ ...user, role: role });
        break;
      case 'user':
        if (field === 'phone_number') {
          value = maskPhone(value);
        }
        if (field === 'type') {
          value = parseInt(value);
          docMask = value === 0 ? '999.999.999-99' : '99.999.999/9999-99';
        }
        setUser({ ...user, [field]: value });
        break;
    }
  }
  //handle avatar upload
  async function handleAvatarUpload(files: any) {
    fileImage = files[0];
  }

  return (
    <div>
      <div className="signup-container">
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <img className="signup-img" src={logo} alt="Siri Logo"></img>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack alignItems="left" p={1}>
                {/* <AvatarUpload src={imagem} width={100} height={100} radius={50} handleUpload={handleAvatarUpload} />
                <small>{user.email}</small>
                <br /> */}
                <p>
                  Tipo de cadastro
                  <RadioGroup key="role.name" name="role.name" row value={role.name} onChange={handleChange}>
                    <FormControlLabel key="rr01" value={'Catador'} label="Catador" control={<Radio />} />
                    <FormControlLabel key="rr02" value={'Gerador'} label="Gerador" control={<Radio />} />
                  </RadioGroup>
                </p>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack>
                <BigTextField key="user.name" label="Nome" name="user.name" value={user.name} onChange={handleChange} error={errors.name} helperText={errors.name} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack>
                <BigTextField key="user.email" label="e-Mail" name="user.email" value={user.email} onChange={handleChange} error={errors.email} helperText={errors.email} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack>
                <BigTextField
                  key="user.password"
                  type="password"
                  label="Senha"
                  name="user.password"
                  value={user.password}
                  onChange={handleChange}
                  error={errors.password}
                  helperText={errors.password}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack>
                <BigTextField
                  key="user.password2"
                  type="password"
                  label="Repita Senha"
                  name="user.password2"
                  value={user.password2}
                  onChange={handleChange}
                  error={errors.password2}
                  helperText={errors.password2}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6} p={1}>
              <Container sx={{ border: 1, borderRadius: 2, margin: 1 }}>
                <RadioGroup key="user.type" name="user.type" row value={user.type} onChange={handleChange}>
                  <FormControlLabel key="rr01" value={0} label="Pessoa Física" control={<Radio />} />
                  <FormControlLabel key="rr02" value={1} label="Pessoa Jurídica" control={<Radio />} />
                </RadioGroup>
              </Container>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack>
                <InputMask mask={docMask} name="user.document" value={user.document} onChange={handleChange} maskChar=" ">
                  <MediumTextField label="CPF/CNPJ" error={errors.document} helperText={errors.document} />
                </InputMask>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack>
                <MediumTextField
                  key="user.phone_number"
                  label="Telefone"
                  name="user.phone_number"
                  value={user.phone_number}
                  onChange={handleChange}
                  error={errors.phone_number}
                  helperText={errors.phone_number}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack>
                <FormControl sx={{ m: 1 }}>
                  <InputLabel>Empresa</InputLabel>
                  <Select key="user.company_id" label="Empresa" name="user.company_id" value={user.company_id} onChange={handleChange} error={errors.company_id}>
                    {getAuthUser().role.name === 'Super Admin' && (
                      <MenuItem key="0" value="0">
                        Nenhum vinculo a empresas
                      </MenuItem>
                    )}
                    {possibleCompanies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.fantasy_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack>
                <MediumTextField
                  key="user.job_title"
                  label="Cargo/Função"
                  name="user.job_title"
                  value={user.job_title}
                  onChange={handleChange}
                  error={errors.job_title}
                  helperText={errors.job_title}
                />
              </Stack>
            </Grid>

            {/* Endereço */}
            <Grid item xs={12} md={1.4}>
              <Stack>
                <InputMask mask="99.999-999" name="address.zip_code" value={address.zip_code} onChange={handleChange} onBlur={handleBlurZipCode} maskChar="">
                  <SmallTextField label="CEP" error={errors.zip_code} helperText={errors.zip_code} sx={{ maxWidth: 200 }} />
                </InputMask>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3.6}>
              <Stack>
                <BigTextField key="address.street" label="Rua" name="address.street" value={address.street} onChange={handleChange} error={errors.street} helperText={errors.street} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={1.0}>
              <Stack>
                <SmallTextField
                  key="address.number"
                  label="Número"
                  name="address.number"
                  value={address.number}
                  onChange={handleChange}
                  error={errors.number}
                  helperText={errors.number}
                  sx={{ maxWidth: 200 }}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack>
                <BigTextField
                  key="address.complement"
                  label="Complemento"
                  name="address.complement"
                  value={address.complement}
                  onChange={handleChange}
                  error={errors.complement}
                  helperText={errors.complement}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Stack>
                <MediumTextField
                  key="address.neighborhood"
                  label="Bairro"
                  name="address.neighborhood"
                  value={address.neighborhood}
                  onChange={handleChange}
                  error={errors.neighborhood}
                  helperText={errors.neighborhood}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={5}>
              <Stack>
                <MediumTextField key="address.city" label="Cidade" name="address.city" value={address.city} onChange={handleChange} error={errors.city} helperText={errors.city} />
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <Stack>
                <FormControl sx={{ maxWidth: 150, m: 1 }} margin="dense">
                  <InputLabel>Estado</InputLabel>
                  <Select key="address.state" label="Estado" name="address.state" value={address.state} onChange={handleChange} error={errors.address} placeholder="Estado">
                    {allStatesNames.map((state) => (
                      <MenuItem key={state.abbreviation} value={state.abbreviation}>
                        {state.abbreviation}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={2} justifyContent="flex-end" m={2}>
              <Button
                onClick={() => {
                  navigate('/login');
                }}>
                Voltar
              </Button>

              <Button color="secondary" onClick={handleSubmit} variant="contained">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
