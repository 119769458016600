import api from './api';
import { IPositiveImpact, blankPositiveImpact, positiveImpactProps } from '../models/PositiveImpact';
import { ICollectTreatment, blankCollectTreatment, collectTreatmentProps } from '../models/CollectTreatment';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';

export class CollectTreatmentService {
  static async getAll(): Promise<ICollectTreatment[]> {
    try {
      const response = await api.get('/reports/collect_residues_treatment');
      let allPositiveImpacts = response.data as ICollectTreatment[];
      return allPositiveImpacts;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar Impactos Positivoss');
      return [];
    }
  }

  static async getByMonth(id: number, init_date: string, end_date: string): Promise<ICollectTreatment[]> {
    try {
      const response = await api.get(`/reports/collect_residues_treatment/${id}?init_date=${init_date}&end_date=${end_date}`);
      let allPositiveImpacts = response.data as ICollectTreatment[];
      return allPositiveImpacts;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar Impactos Positivoss');
      return [];
    }
  }

  static async getById(id: number): Promise<ICollectTreatment[]> {
    try {
      const response = await api.get(`/reports/collect_residues_treatment/${id}`);
      let allPositiveImpacts = response.data as ICollectTreatment[];
      return allPositiveImpacts;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar Impactos Positivos!');
      return [];
    }
  }

  // static async create({ id, ...positiveImpact }: ICollectTreatment): Promise<ICollectTreatment> {
  //   try {
  //     this.standarlizePositiveImpact(positiveImpact);
  //     const response = await api.post('/positiveImpacts', positiveImpact);
  //     if (response.status !== 201) {
  //       toast.error('Erro ao Incluir Impactos Positivos');
  //       return blankPositiveImpact;
  //     }
  //     toast.success('Veículo incluído com sucesso!');
  //     return response.data as ICollectTreatment;
  //   } catch (error: any) {
  //     toast.error('Erro ao incluir Impactos Positivos!');
  //     return blankPositiveImpact;
  //   }
  // }
  // static async update({ ...positiveImpact }: ICollectTreatment): Promise<boolean> {
  //   try {
  //     this.standarlizePositiveImpact(positiveImpact);
  //     let response = await api.put(`/positiveImpacts/${positiveImpact.id}`, positiveImpact);
  //     if (response.status !== 200) {
  //       toast.error('Erro ao Atualizar Impactos Positivos!');
  //       return false;
  //     }
  //     toast.success('Veículo atualizado com sucesso!');
  //     return true;
  //   } catch (error: any) {
  //     toast.error('Erro ao Atualizar Impactos Positivos!');
  //     return false;
  //   }
  // }

  // static async delete(id: number): Promise<boolean> {
  //   try {
  //     let response = await api.delete('/positiveImpacts/' + id);
  //     if (response.status !== 200) {
  //       toast.error('Erro ao Excluir Impactos Positivos!');
  //       return false;
  //     }
  //     toast.success('Veículo excluído com sucesso!');
  //     return true;
  //   } catch (error: any) {
  //     toast.error('Erro ao Excluir Impactos Positivos!');
  //     return false;
  //   }
  // }

  static standarlizePositiveImpact(v: ICollectTreatment | any): ICollectTreatment {
    return v;
  }
}

export default CollectTreatmentService;
