import { useEffect, useState } from 'react';
import styled, { styled as sx } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import { Grid, Select, MenuItem, Stack, InputLabel, FormControl, FormControlLabel, Button, DialogActions, Container, Typography, TextField, FormLabel, FormGroup, Checkbox } from '@mui/material';

import { IUnit, blankUnit } from '../../models/Unit';
import { ICompany } from '../../models/Company';

import { toast } from 'react-toastify';

import UnitService from '../../services/UnitService';
import { validateRequired } from '../../utils/validations';

import { getModelFromField } from '../../utils/MyLib';
import { ConfirmModal } from '../../components/ConfirmModal';
import { Dialog } from '@material-ui/core';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';

export default function ResidueForm() {
  const [unit, setUnit] = useState<IUnit>(blankUnit);
  const [errors, setErrors] = useState<any>({});
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir a unidade?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const navigate = useNavigate();

  let { id } = useParams();

  //Get all collectors
  async function fetchData() {
    try {
      let unit = blankUnit;
      if (Number(id)) {
        unit = await UnitService.getById(Number(id));
      }
      setUnit(unit);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(unit: IUnit) {
    let errors: any = {};
    if (!validateRequired(unit.name)) errors.name = 'Obrigatorio';
    if (!validateRequired(unit.abreviation)) errors.description = 'Obrigatorio';
    return errors;
  }

  async function save(unit: IUnit) {
    if (unit.id) {
      if (await UnitService.update(unit)) {
        toast.success('Resíduo salva com sucesso');
      }
    } else {
      let item = await UnitService.create(unit);
      if (item.id > 0) {
        unit.id = item.id;
        toast.success('Resíduo criada com sucesso');
      }
    }
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    const errors = validateErrors(unit);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      await save(unit);
      navigate('/units');
    }
  }

  function handleDeleteConfirmation(unit: IUnit) {
    setConfirmMessage('Deseja excluir "' + unit.name + '" ?');
    setShowConfirmationModal(true);
  }
  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await UnitService.delete(unit.id)) {
      toast.success('Resíduo apagada com sucesso');
      unit.id = -1;
      navigate('/units');
    }
  }

  //Get values on form inputs and put into the model Unit
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    switch (field) {
      default:
        setUnit({ ...unit, [field]: value });
        break;
    }
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={true} maxWidth="sm" disableEnforceFocus={true}>
          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <PageTitle>CADASTRO DE UNIDADES</PageTitle>
            <CloseModal
              onClick={() => {
                navigate('/units');
              }}
            />
          </Container>

          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                  Informações
                </Typography>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="unit.id" label="Id" name="unit.id" value={unit.id} onChange={() => {}} error={errors.id} placeholder="Id" disabled />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="unit.name" label="Nome" name="unit.name" value={unit.name} onChange={handleChange} error={errors.name} placeholder="Nome" />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="unit.abreviation" label="Sigla" name="unit.abreviation" value={unit.abreviation} onChange={handleChange} error={errors.abreviation} placeholder="Sigla" />
                    </FormControl>
                  </Stack>
                </Grid>{' '}
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="unit.codInea" label="Cód.Inea" name="unit.codInea" value={unit.codInea} onChange={handleChange} error={errors.codInea} placeholder="Cód.Ineap" />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
              <DialogActions sx={{ p: '1.25rem' }}>
                <Button
                  color="warning"
                  disabled={unit.id === 0}
                  onClick={() => {
                    handleDeleteConfirmation(unit);
                  }}>
                  Apagar
                </Button>
                <Button
                  onClick={() => {
                    navigate('/units');
                  }}>
                  Voltar
                </Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </Container>

          <ConfirmModal title="Deleção de Resíduo" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
