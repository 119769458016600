import React from 'react';
import styled, { css } from 'styled-components';

const CardsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 10rem;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: var(--shadow-2);
`;

const ImgContainer = styled.div`
  width: 45%;
  padding: 10px 0 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
`;

const TextContainer = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 35px;
`;

const MainText = styled.h1`
  font-size: 2rem;
  line-height: 2.6rem;

  ${css`
    @media (max-width: 1220px) {
      font-size: 1.5rem;
    }
  `}
`;

const SubText = styled.h5`
  font-size: 0.75rem;
  text-transform: uppercase;
`;

type ImpactCardProps = {
  impact: number;
  text: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export default function ImpactCard({ impact, text, Icon }: ImpactCardProps) {
  return (
    <CardsContainer>
      <ImgContainer>
        <Icon width={125} height={100} />
      </ImgContainer>
      <TextContainer>
        <MainText>{impact}</MainText>
        <SubText>{text}</SubText>
      </TextContainer>
    </CardsContainer>
  );
}
