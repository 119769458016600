import { AccountCircle, Delete, Download } from '@mui/icons-material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Button, Stack, Typography } from '@mui/material';
import 'dayjs/locale/pt-br';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_TableInstance, type MRT_Row } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmModal } from '../../components/ConfirmModal';
import { LoadWrapper, PageContainer, PageContent, PageFooter, PageHeader, PageTitle } from '../../components/GlobalStyleds.jsx';
import PageStructure from '../../components/PageStructure';
import { ICompany } from '../../models/Company';
import { INeighborhood, blankNeighborhood } from '../../models/Neighborhood';
import CompanyService from '../../services/CompanyService';
import NeighborhoodService from '../../services/NeighborhoodService';
import { NeighborCreationModal } from './NeighborCreationModal';
import { NeighborhoodModal } from './NeighborhoodModal';
import './NeighborhoodPage.css';
import { getAuthUser } from '../../services/auth';

export default function NeighborhoodPage() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [neighborhoodsData, setNeighborhoodsData] = useState<INeighborhood[]>([]);
  const [loading, setLoading] = useState(true);
  const [neighborhood, setNeighborhood] = useState<INeighborhood>(blankNeighborhood);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [id, setDeleteId] = useState(0);
  const navigate = useNavigate();
  const tableInstanceRef = useRef<MRT_TableInstance<INeighborhood>>(null);
  const [rowSelection, setRowSelection] = useState({});
  const [companiesData, setCompaniesData] = useState<ICompany[]>([]);

  function handleNeighborhoodsCompanyName(neighborhoodInfo: INeighborhood[], companyInfo: ICompany[]) {
    setNeighborhoodsData(
      neighborhoodInfo.map((neighborhood) => {
        neighborhood.company_name = companyInfo.find((company) => company.id === neighborhood.company_id)?.fantasy_name;
        return neighborhood;
      })
    );
  }

  async function fetchData() {
    setLoading(true);
    let neighborhoodsApiData = await NeighborhoodService.getAll();
    if (getAuthUser().role.name !== 'Super Admin') {
      neighborhoodsApiData = neighborhoodsApiData.filter((n) => n.company_id === getAuthUser().company.id);
    }

    let companiesApiData = await CompanyService.getAll();
    handleNeighborhoodsCompanyName(neighborhoodsApiData, companiesApiData);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  //Inefficient
  async function handleSave(neighborhoods: INeighborhood[]) {
    setLoading(true);
    let nothingChanged = 0;
    //Creating
    neighborhoods.map((neighborhood) => {
      if (!neighborhoodsData.find((e) => e.company_id === neighborhood.company_id && e.state === neighborhood.state && e.city === neighborhood.city && e.neighborhood === neighborhood.neighborhood)) {
        NeighborhoodService.create(neighborhood);
      }
    });

    //handling neighborhoodsData from the same company and city that neighborhoods
    let neighborhoodsSameCompanyAndCity = neighborhoodsData.filter((neighborhood) =>
      neighborhoods.find((e) => e.company_id === neighborhood.company_id && e.state === neighborhood.state && e.city === neighborhood.city)
    );

    //Deleting
    neighborhoodsSameCompanyAndCity.map((neighborhood) => {
      if (!neighborhoods.find((e) => e.company_id === neighborhood.company_id && e.state === neighborhood.state && e.city === neighborhood.city && e.neighborhood === neighborhood.neighborhood)) {
        NeighborhoodService.delete(neighborhood.id);
      }
    });

    navigate('/neighborhoods');
    setLoading(false);
  }

  // Abre o modal de criação de um novo bairro
  function handleNewRow() {
    let company_id = getAuthUser().company?.id ? getAuthUser().company.id : 0;
    setNeighborhood({ ...blankNeighborhood, company_id: company_id });
    setCreateModalOpen(true);
  }

  // Exclui o bairro
  function handleDeleteConfirmation() {
    if (Object.keys(rowSelection).length === 0) {
      toast.error('Selecione um bairro para excluir');
      return;
    }
    setShowConfirmationModal(true);
  }

  //handle delete
  function handleDelete() {
    setLoading(true);
    for (let prop in rowSelection) {
      NeighborhoodService.delete(Number(prop));
    }
    setShowConfirmationModal(false);
    fetchData();
    setLoading(false);
  }

  // Busca o usuário no backend e abre o modal de edição
  async function handleEditRow(row: MRT_Row<INeighborhood>) {
    // setLoading(true);
    let edit = await NeighborhoodService.getById(row.getValue('id'));
    if (edit.id >= 0) {
      setNeighborhood(edit);
      setEditModalOpen(true);
    }
  }

  function handleEdit(neighborhood: INeighborhood) {
    setLoading(true);
    NeighborhoodService.update(neighborhood);
    setLoading(false);
    fetchData();
    setEditModalOpen(false);
  }

  //Export to csv
  function handleExport() {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = neighborhoodsData.map((c) => {
      return {
        id: c.id,
        neighborhood: c.neighborhood,
        city: c.city,
        state: c.state,
        company: c.company_name
      };
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  const columns = useMemo<MRT_ColumnDef<INeighborhood>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 5
      },
      {
        accessorKey: 'company_name',
        header: 'Empresa'
      },
      {
        accessorKey: 'state',
        header: 'Estado',
        size: 10
      },
      {
        accessorKey: 'city',
        header: 'Cidade'
      },

      {
        accessorKey: 'neighborhood',
        header: 'Bairro'
      },
      {
        accessorKey: 'lat',
        header: 'Latitude/Longitude',
        Cell: ({ row }) => <span>{row.original.lat + ' ' + row.original.lat}</span>
      }
    ],

    []
  );
  // Define a custom function to format the date

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>ÁREA DE ATENDIMENTO</PageTitle>
        </PageHeader>
        <PageContent>
          {neighborhoodsData.length === 0 && (
            <Stack direction="column" sx={{ marginTop: '100px' }} justifyContent="center" alignItems="center" spacing={0}>
              <Typography variant="h4">Nenhum bairro encontrado</Typography>

              <PersonSearchIcon sx={{ fontSize: 120 }} />

              <Button
                size="medium"
                sx={{
                  width: 400,
                  height: 50
                }}
                color="primary"
                onClick={handleNewRow}
                variant="contained">
                NOVA ÁREA DE ATENDIMENTO
              </Button>
            </Stack>
          )}
          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {neighborhoodsData.length > 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={neighborhoodsData}
              enableRowSelection
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false } }}
              renderTopToolbarCustomActions={({ table }) => (
                <Stack direction="row" spacing={1}>
                  <Button color="primary" onClick={handleNewRow} variant="contained">
                    NOVA ZONA DE ATENDIMENTO
                  </Button>
                  <Button color="primary" onClick={handleDeleteConfirmation} variant="contained" startIcon={<Delete />}>
                    Excluir
                  </Button>
                  <Button color="secondary" onClick={handleExport} startIcon={<Download />} variant="contained">
                    Exportar
                  </Button>
                </Stack>
              )}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  handleEditRow(row);
                }
              })}
            />
          )}
          <NeighborhoodModal value={neighborhood} open={editModalOpen} onClose={() => setEditModalOpen(false)} onSubmit={handleEdit} />
          <NeighborCreationModal value={neighborhood} open={createModalOpen} onClose={() => setCreateModalOpen(false)} onSubmit={handleSave} neighborhoodsData={neighborhoodsData} />
          <ConfirmModal title="Deleção de Bairros" message="Deseja excluir o(s) bairro(s)?" isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
