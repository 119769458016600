import { useEffect, useMemo, useState, useRef } from 'react';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_Row, type MRT_TableInstance } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { Download } from '@mui/icons-material';
import { IUser, blankUser } from '../../models/User';
import PageStructure from '../../components/PageStructure';
import { PageContainer, PageContent, PageHeader, PageFooter, PageTitle, LoadWrapper } from '../../components/GlobalStyleds.jsx';
import UserService from '../../services/UserService';
import ReactLoading from 'react-loading';
import './UserPage.css';
import { ConfirmModal } from '../../components/ConfirmModal';
import CardsContainer from '../../components/CardsContainer';

import { Box, Avatar, Typography, Stack, Button, Icon } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckCircleOutlineSharp from '@mui/icons-material/CheckCircleOutlineSharp';
import { Login } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';
import 'dayjs/locale/pt-br';
import ListItemText from '@mui/material/ListItemText';
import { formatDate } from '../../utils/MyLib';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { baseImg } from '../../App';
import { ICompany } from '../../models/Company';
import CompanyService from '../../services/CompanyService';
import { display } from '@mui/system';
import { getAuthUser } from '../../services/auth';
// import { ptBR } from '@mui/x-date-pickers/locales';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function UserPage() {
  // const [createModalOpen, setCreateModalOpen] = useState(false);
  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(true);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir o usuário?');
  const tableInstanceRef = useRef<MRT_TableInstance<IUser>>(null);
  const [rowSelection, setRowSelection] = useState({});
  // const [user, setUser] = useState<IUser>(blankUser);
  let cardChoiced = 0;
  let allCompanies: ICompany[] = [];
  CompanyService.getAll().then((result) => {
    allCompanies = result;
  });
  let allUsers: IUser[] = [];

  const navigate = useNavigate();

  const [cards, setCards] = useState([
    { id: 0, title: 'Total de Usuários', content: 0 },
    { id: 1, title: 'Geradores Residenciais', content: 0 },
    { id: 2, title: 'Admin Empresas', content: 0 },
    { id: 3, title: 'Geradores Comerciais', content: 0 },
    { id: 4, title: 'Operadores Empresa ', content: 0 },
    { id: 5, title: 'Catadores ', content: 0 }
  ]);

  async function fetchData() {
    if (['Gerador', 'Gerador Comercial', 'Catador'].includes(getAuthUser().role.name)) {
      navigate('/home');
    }
    setLoading(true);
    allUsers = await UserService.getAll();
    if (['Admin', 'Operador'].includes(getAuthUser().role.name)) {
      allUsers = allUsers.filter((u) => u.company_id === getAuthUser().company_id);
    }

    allUsers.map((u) => {
      //buscar nome das empresas
      u.company_name = allCompanies.find((company) => company.id === u.company_id)?.fantasy_name;
      return u;
    });

    // setUser(blankUser);
    setLoading(false);
    cards[0].content = allUsers.length;
    cards[1].content = allUsers.filter((u) => u.role.name === 'Gerador').length;
    cards[2].content = allUsers.filter((u) => u.role.name === 'Admin').length;
    cards[3].content = allUsers.filter((u) => u.role.name === 'Gerador Comercial').length;
    cards[4].content = allUsers.filter((u) => u.role.name === 'Operador').length;
    cards[5].content = allUsers.filter((u) => u.role.name === 'Catador').length;
    setCards([...cards]);
    allUsers = cardChoiced === 1 ? allUsers.filter((u) => u.role.name === 'Gerador') : allUsers;
    allUsers = cardChoiced === 2 ? allUsers.filter((u) => u.role.name === 'Admin') : allUsers;
    allUsers = cardChoiced === 3 ? allUsers.filter((u) => u.role.name === 'Gerador Comercial') : allUsers;
    allUsers = cardChoiced === 4 ? allUsers.filter((u) => u.role.name === 'Operador') : allUsers;
    allUsers = cardChoiced === 5 ? allUsers.filter((u) => u.role.name === 'Catador') : allUsers;

    setUsersData(allUsers);
  }

  useEffect(() => {
    fetchData();
  }, []);

  // Function called when the modal closes with confirm button
  function handleSave(user: IUser, operation: string): void {
    // const index = usersData.findIndex((u) => u.id === user.id);
    // switch (operation) {
    //   case 'create':
    //     usersData.push(user);
    //     break;
    //   case 'update':
    //     usersData.splice(index, 1);
    //     break;
    //   case 'delete':
    //     usersData.splice(index, 1);
    //     break;
    //   default:
    //     break;
    // }
    // setCreateModalOpen(false);
    // setUsersData(usersData);
    window.location.reload();
  }

  // Abre o modal de criação de um novo usuário
  function handleNewRow() {
    navigate(`/users/0`);
  }

  // Busca o usuário no backend e abre o modal de edição
  async function handleEditRow(row: MRT_Row<IUser>) {
    // setLoading(true);
    // let u = await UserService.getById(row.getValue('id'));
    // if (u.id >= 0) {
    //   setUser(u);
    //   setLoading(false);
    //   setCreateModalOpen(true);
    // }
    navigate(`/users/${row.getValue('id')}`);
  }

  // Exclui o usuário
  function handleDelete() {
    // setShowConfirmationModal(false);
    // setLoading(true);
    // UserService.delete(user.id).then((result) => {
    //   const index = usersData.findIndex((u) => u.id === user.id);
    //   usersData.splice(index, 1);
    //   setLoading(false);
    // });
  }

  // Exclui o usuário
  function handleDeleteConfirmation(row: MRT_Row<IUser>) {
    // setConfirmMessage('Deseja excluir o usuário ' + row.getValue('name') + '?');
    // setShowConfirmationModal(true);
  }

  //TODO: Implementar exportação
  function handleExport() {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = usersData.map((u) => {
      return {
        id: u.id,
        name: u.name,
        email: u.email,
        phone_number: u.phone_number,
        role: u.role.name,
        document: u.document,
        active: u.active,
        created_at: u.created_at
      };
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  async function handleActive(e: any) {
    for (var prop in rowSelection) {
      let user = await UserService.getById(Number(prop));
      if (e.target.name === 'btnActive') user.active = 1;
      else user.active = -1;
      await UserService.update(user);
    }
    fetchData();
  }

  async function handleClickFilterCard(id: number) {
    cardChoiced = id;
    fetchData();
  }

  function colorStatus(status: number) {
    switch (status) {
      case 1:
        return 'green';
      case -1:
        return 'red';
      default:
        return 'orange';
    }
  }

  const columns = useMemo<MRT_ColumnDef<IUser>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 5
      },
      {
        accessorKey: 'phone_number',
        header: 'Telefone'
      },

      // {
      //   accessorKey: 'address',
      //   header: ''
      // },
      {
        acessorKey: 'active',
        header: 'Status',
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'space-between',
              gap: '2rem'
            }}>
            <CheckCircleOutlineSharp sx={{ color: colorStatus(row.original.active) }} />
            {['Super Admin'].includes(getAuthUser().role.name) && (
              <div onClick={() => UserService.impersonate(row.original.id)}>
                <Login />
              </div>
            )}
          </Box>
        ),
        size: 20
      },

      {
        accessorKey: 'name',
        id: 'name', //id is still required when using accessorFn instead of accessorKey
        header: 'Nome',
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem'
            }}>
            <Avatar
              sx={{
                height: '30px',
                width: '30px'
              }}
              src={row.original.img ? baseImg + row.original.img : ''}
            />

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        )
      },

      {
        accessorKey: 'email',
        header: 'E-mail',
        size: 200
      },
      {
        accessorKey: 'role.name',
        header: 'Tipo',
        size: 100
      },
      {
        accessorKey: 'wallet.balance',
        header: 'Saldo',
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <Box>
            <span>{renderedCellValue ? Number(renderedCellValue).toFixed(2) : ''}</span>
          </Box>
        )
      }
      // { accessorKey: 'company_name', header: 'Empresa', size: 200 }

      // {
      //   accessorFn: (row) => new Date(row.created_at), //convert to Date for sorting and filtering
      //   id: 'created_at',
      //   size: 100,
      //   header: 'Data de início',
      //   filterFn: 'lessThanOrEqualTo',
      //   sortingFn: 'datetime',
      //   Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
      //   Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      //   //Custom Date Picker Filter from @mui/x-date-pickers
      //   Filter: ({ column }) => (
      //     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt" localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
      //       <DatePicker
      //         onChange={(newValue) => {
      //           column.setFilterValue(newValue);
      //         }}
      //         slotProps={{
      //           textField: {
      //             helperText: 'Data menor que:',
      //             sx: { minWidth: '120px' },
      //             variant: 'standard'
      //           }
      //         }}
      //         value={column.getFilterValue()}
      //       />
      //     </LocalizationProvider>
      //   )
      // }
    ],

    []
  );

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>CENTRAL DE USUÁRIOS</PageTitle>
          <CardsContainer
            cards={cards}
            choiced={cardChoiced}
            onChange={(id) => {
              handleClickFilterCard(id);
            }}></CardsContainer>
        </PageHeader>
        <PageContent>
          {usersData.length === 0 && (
            <Stack direction="column" sx={{ marginTop: '100px' }} justifyContent="center" alignItems="center" spacing={0}>
              <Typography variant="h4">Nenhum usuário encontrado</Typography>

              <PersonSearchIcon sx={{ fontSize: 120 }} />

              <Button
                size="medium"
                sx={{
                  width: 300,
                  height: 50
                }}
                color="primary"
                onClick={handleNewRow}
                variant="contained"
                startIcon={<AccountCircle />}>
                Novo
              </Button>
            </Stack>
          )}

          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {usersData.length > 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={usersData}
              enableRowSelection
              // enableRowActions
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false, phone_number: false, cpf: false } }}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  handleEditRow(row);
                }
              })}
              // renderRowActionMenuItems={({ row }) => [
              //   <MenuItem
              //     dense
              //     key={0}
              //     onClick={() => {
              //       // View profile logic...
              //       handleEditRow(row);
              //     }}
              //     sx={{ m: 0 }}>
              //     <ListItemIcon>
              //       <Edit />
              //     </ListItemIcon>
              //     Editar usuário
              //   </MenuItem>,
              //   <MenuItem
              //     dense
              //     key={1}
              //     onClick={() => {
              //       // Send email logic...
              //       handleDeleteConfirmation(row);
              //     }}
              //     sx={{ m: 0 }}>
              //     <ListItemIcon>
              //       <Delete />
              //     </ListItemIcon>
              //     Excluir usuário
              //   </MenuItem>
              // ]}
              renderTopToolbarCustomActions={({ table }) => (
                <Stack direction="row" spacing={1}>
                  <Button color="primary" onClick={handleNewRow} variant="contained" startIcon={<AccountCircle />}>
                    Novo
                  </Button>
                  <Button name="btnActive" color="secondary" onClick={handleActive} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Ativar
                  </Button>
                  <Button name="btnInactive" color="secondary" onClick={handleActive} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Desativar
                  </Button>
                  <Button color="secondary" onClick={handleExport} startIcon={<Download />} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Exportar
                  </Button>
                </Stack>
              )}
              renderDetailPanel={({ row }) => (
                <Grid container rowSpacing={0} alignItems="center">
                  {/* 1 */}
                  <Grid item xs={1} alignItems="center" justifyContent="center">
                    <Avatar sx={{ height: 70, width: 70 }} src={row.original.img ? baseImg + row.original.img : ''} />
                  </Grid>
                  <Grid item xs={2} alignContent="left" sx={{ marginBottom: 2, paddingRight: 2 }}>
                    <ListItemText primaryTypographyProps={{ style: { fontSize: '1.1rem', fontWeight: 'bold', wordWrap: 'break-word' } }} primary={row.original.name} />
                  </Grid>
                  <Grid item xs={3} alignContent="left">
                    <ListItemText
                      secondaryTypographyProps={{ style: { fontSize: '1.1rem', color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      secondary={row.original.id}
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText
                      secondary="Data criação"
                      primaryTypographyProps={{ style: { color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      primary={formatDate(row.original.created_at)}
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText
                      secondary="Última Edição"
                      primaryTypographyProps={{ style: { color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      primary={formatDate(row.original.updated_at)}
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText
                      secondary="Status"
                      primary={row.original.active ? 'Ativo' : 'Inativo'}
                      primaryTypographyProps={{ style: { color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    />
                  </Grid>
                  {/* 2 */}
                  <Grid item xs={3} alignContent="left">
                    <ListItemText secondary="Nome" primary={row.original.name} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={3} alignContent="left">
                    <ListItemText secondary="Telefone" primary={row.original.phone_number} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={3} alignContent="left">
                    <ListItemText secondary="Cep" primary={row.original.address.zip_code} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={1} alignContent="left">
                    <ListItemText secondary="N°" primary={row.original.address.number} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText secondary="Complemento" primary={row.original.address.complement} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  {/* 3 */}
                  <Grid item xs={3} alignContent="left">
                    <ListItemText secondary="Email" primary={row.original.email} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={3} alignContent="left">
                    <ListItemText secondary="CPF/CNPJ" primary={row.original.document} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={3} alignContent="left">
                    <ListItemText secondary="Endereço" primary={row.original.address.street} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText secondary="Cidade" primary={row.original.address.city} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                </Grid>
              )}
            />
          )}

          {/* <UserModal value={user} open={createModalOpen} onClose={() => setCreateModalOpen(false)} onSubmit={handleSave} /> */}
          <ConfirmModal title="Deleção de Usuários" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
