import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { styled as sx } from 'styled-components';
import { Dialog } from '@material-ui/core';
import { Button, Checkbox, Container, DialogActions, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';
import { ConfirmModal } from '../../components/ConfirmModal';
import { PageContainer, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import { ICompany } from '../../models/Company';
import { blankPack, IPack } from '../../models/Pack';
import { blankResidue, IResidue } from '../../models/Residue';
import { protectForm } from '../../services/auth';
import { CompanyService } from '../../services/CompanyService';
import PackService from '../../services/PackService';
import ResidueService from '../../services/ResidueService';
import { getModelFromField } from '../../utils/MyLib';
import { validateRequired } from '../../utils/validations';

const Separator = styled.hr`
  border: 1px solid lightgray; /* Cor da linha branca */
`;

export default function ResidueForm() {
  const [pack, setPack] = useState<IPack>(blankPack);
  const [errors, setErrors] = useState<any>({});
  const [allCompanies, setAllCompanies] = useState<ICompany[]>([]);
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir o resíduo?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [allResiduesSubClass, setAllResiduesSubClass] = useState<IResidue[]>([]);
  const [allResiduesLevelUp, setAllResiduesLevelUp] = useState<IResidue[]>([]);
  const [allResidues, setAllResidues] = useState<IResidue[]>([]);
  const [rType, setRType] = useState(0);

  const navigate = useNavigate();

  let { id } = useParams();

  //Get all collectors
  async function fetchData() {
    let company = await protectForm('/packs');
    pack.company_id = company.id;
    try {
      let allResidues = await ResidueService.getAll();
      let allResiduesLevelUp = allResidues.filter((residue) => residue.level <= 2);
      let allResiduesSubClass = allResidues.filter((residue) => residue.level === 3);
      setAllResiduesLevelUp(allResiduesLevelUp);
      setAllResiduesSubClass(allResiduesSubClass);
      setAllResidues(allResidues);

      let companiesApiData = await CompanyService.getAllColetadoras();
      setAllCompanies(companiesApiData);

      let pack = blankPack;
      if (Number(id)) {
        pack = await PackService.getById(Number(id));
      }
      setPack(pack);

      // Trocar pelo metodo que busque no servidor apenas as coletas daquela resíduo
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(pack: IPack) {
    let errors: any = {};
    if (!validateRequired(pack.name)) errors.name = 'Obrigatorio';
    if (!validateRequired(pack.description)) errors.description = 'Obrigatorio';
    if (!pack.company_id) errors.company_id = 'Obrigatorio';

    return errors;
  }

  async function saveResidue(pack: IPack) {
    if (pack.id) {
      if (await PackService.update(pack)) {
        toast.success('Resíduo salva com sucesso');
      }
    } else {
      let newResidue = await PackService.create(pack);
      if (newResidue.id > 0) {
        pack.id = newResidue.id;
        toast.success('Resíduo criada com sucesso');
      }
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    const errors = validateErrors(pack);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveResidue(pack);
      navigate('/packs');
    }
  }

  function handleDeleteConfirmation(pack: IPack) {
    setConfirmMessage('Deseja excluir o serviço ' + pack.name + ' ?');
    setShowConfirmationModal(true);
  }
  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await PackService.delete(pack.id)) {
      toast.success('Serviço apagado com sucesso');
      pack.id = -1;
      navigate('/packs');
    }
  }

  //Get values on form inputs and put into the model Pack
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    switch (field) {
      case 'residue_type_id':
        let residue = allResidues.find((residue) => residue.id === value) || blankResidue;
        let allResiduesSubClass = allResidues.filter((rt) => residue.code === rt.code.slice(0, residue.code.length) && rt.level === 3);
        setAllResiduesSubClass(allResiduesSubClass);
        setRType(value);
        break;

      default:
        setPack({ ...pack, [field]: value });
        break;
    }
  }

  async function handleCompositionChange(rt: IResidue) {
    //if a value exists in nighborhoods list then remove it
    let composition = pack.composition;
    if (composition.find((c) => c.residue_id === rt.id)) {
      composition = composition.filter((c) => c.residue_id !== rt.id);
    } else {
      composition.push({ residue_id: rt.id, code: rt.code, description: rt.description, unit: rt.unit?.abreviation });
      composition.sort();
    }
    setPack({ ...pack, composition: composition });
  }

  function isMarked(rt: IResidue) {
    if (pack.composition.find((c) => c.residue_id === rt.id)) {
      return true;
    }
    return false;
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={true} maxWidth="lg" disableEnforceFocus={true}>
          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <PageTitle>CADASTRO DE SERVIÇOS</PageTitle>
            <CloseModal
              onClick={() => {
                navigate('/packs');
              }}
            />
          </Container>

          <Separator />
          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                  Informações
                </Typography>

                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <InputLabel>Empresa</InputLabel>
                      <Select key="pack.company_id" label="Empresa" name="pack.company_id" value={pack.company_id} onChange={handleChange} error={errors.comany_id} placeholder="Empresa">
                        {allCompanies.map((company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {company.fantasy_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="pack.name" label="Nome" name="pack.name" value={pack.name} onChange={handleChange} error={errors.name} placeholder="Nome" />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField
                        key="pack.descripiton"
                        multiline
                        rows={4}
                        label="Descrição"
                        name="pack.description"
                        value={pack.description}
                        onChange={handleChange}
                        error={errors.description}
                        placeholder="Descrição"
                      />
                    </FormControl>
                  </Stack>
                </Grid>
                <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                  Tipos Disponíveis
                </Typography>
                <Grid item xs={12} md={6}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <InputLabel>Classe/SubClasse</InputLabel>
                      <Select
                        key="pack.residue_type_id"
                        label="Tipo de residuo"
                        name="pack.residue_type_id"
                        value={rType}
                        onChange={handleChange}
                        // error={errors.residue_type_id}
                        placeholder="Tipo de residuo">
                        {allResiduesLevelUp.map((residue) => (
                          <MenuItem key={residue.id} value={residue.id}>
                            {residue.code + ' - ' + residue.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl margin="dense">
                      <FormLabel>
                        <Typography variant="h6">Residuos</Typography>
                      </FormLabel>
                      <FormGroup>
                        {allResiduesSubClass.map((rt) => (
                          <FormControlLabel
                            key={rt.id}
                            control={<Checkbox color="primary" name="pack.composition" checked={isMarked(rt)} />}
                            label={rt.code + ' - ' + rt.description}
                            name="pack.composition"
                            onChange={(e) => handleCompositionChange(rt)}
                            value={rt.id}
                            style={{ width: '100%', margin: '0.0rem', fontSize: '0.3rem' }}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
              <DialogActions sx={{ p: '1.25rem' }}>
                <Button
                  color="warning"
                  disabled={pack.id === 0}
                  onClick={() => {
                    handleDeleteConfirmation(pack);
                  }}>
                  Apagar
                </Button>
                <Button
                  onClick={() => {
                    navigate('/packs');
                  }}>
                  Voltar
                </Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </Container>

          <ConfirmModal title="Deleção de Resíduo" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
