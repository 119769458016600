import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarDashBoardContainer = styled.div`
  padding: 0 10px;
  width: 100%;
  height: 300px;
`;

type PieDashBoardProps = {
  data_1?: number[];
  data_2?: number[];
  data_3?: number[];
};

export default function ImpactBar({ data_1, data_2, data_3 }: PieDashBoardProps) {
  const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Total Coletado',
        data: data_1,
        backgroundColor: '#000',
        borderColor: '#000',
        borderWidth: 1
      },
      {
        label: 'Reciclado',
        data: data_2,
        backgroundColor: '#01FF51',
        borderColor: '#01FF51',
        borderWidth: 1
      },
      {
        label: 'Compostagem',
        data: data_3,
        backgroundColor: '#01D3E5',
        borderColor: '#01D3E5',
        borderWidth: 1
      }
    ]
  };

  return (
    <BarDashBoardContainer>
      <Bar
        data={data}
        options={{
          plugins: {
            legend: {
              display: true,
              position: 'right',
              labels: {
                boxWidth: 15,
                boxHeight: 15
              }
            }
          }
        }}
      />
    </BarDashBoardContainer>
  );
}
