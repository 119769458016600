import api from './api';
import { IUser, blankUser, userProps } from '../models/User';
import { mapNullToString, clearObj, capitalizeObj } from '../utils/MyLib';
import { toast } from 'react-toastify';
import { getAuthUser } from '../services/auth';
import { login } from '../services/auth';
import { useNavigate } from 'react-router-dom';

export class UserService {
  navigate = useNavigate();
  static async getAll(): Promise<IUser[]> {
    try {
      const response = await api.get('/users');
      let allUsers = response.data as IUser[];
      if (['Admin', 'Operador', 'Catador'].includes(getAuthUser().role.name)) {
        allUsers = allUsers.filter((u) => u.company_id === getAuthUser().company_id);
      }
      if (['Gerador', 'Gerador Comercial'].includes(getAuthUser().role.name)) {
        allUsers = allUsers.filter((u) => u.id === getAuthUser().id);
      }
      return allUsers;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar usuários');
      return [];
    }
  }

  static async getGenerators(): Promise<IUser[]> {
    try {
      const response = await api.get('/users');
      let allUsers = response.data as IUser[];
      let allGenerators = allUsers.filter((u) => u.role.name === 'Gerador' || u.role.name === 'Gerador Comercial');
      return allGenerators;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar usuários geradores');
      return [];
    }
  }

  static async getById(id: number): Promise<IUser> {
    try {
      const response = await api.get(`/users/${id}`);
      let u = mapNullToString(response.data);
      clearObj(u, userProps);
      return u;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar usuário!');
      return blankUser;
    }
  }

  static async impersonate(id: number) {
    try {
      const response = await toast.promise(api.post('/auth/impersonate/' + id), {
        pending: 'Aguarde...',
        success: 'Login realizado',
        error: 'Erro ao realizar login'
      });
      login(response.data);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }
  static async create({ id, ...user }: IUser): Promise<IUser> {
    try {
      this.standarlizeUser(user);
      const response = await api.post('/users', user);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir usuários');
        return blankUser;
      }
      toast.success('Usuário incluído com sucesso!');
      return response.data as IUser;
    } catch (error: any) {
      toast.error('Erro ao Incluir usuários');
      return blankUser;
    }
  }

  static async update(user: IUser): Promise<IUser> {
    try {
      this.standarlizeUser(user);
      let response = await api.put(`/users/${user.id}`, user);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar usuários');
        return blankUser;
      }
      toast.success('Usuário atualizado com sucesso!');
      return response.data as IUser;
    } catch (error: any) {
      toast.error('Erro ao Atualizar usuários');
      return blankUser;
    }
  }
  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/users/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir usuários');
        return false;
      }
      toast.success('Usuário excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir usuários');
      return false;
    }
  }

  static standarlizeUser(u: IUser | any): IUser {
    u.active = Number(u.active);
    u.type = Number(u.type);
    if (u.company_id === '0') {
      u.company_id = undefined;
    }
    if (!u.company_id) {
      u.company_id = undefined;
    }
    if (!u.address.id) {
      u.address.id = undefined;
    }
    return capitalizeObj(u);
  }
}

export default UserService;
