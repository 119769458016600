import { IAddress, blankAddress } from './Address';
import { IUser, blankUser } from './User';

export interface ICompany {
  id: number;
  fantasy_name: string;
  business_name: string;
  cnpj: string;
  description: string;
  logo: string;
  phone_number: string;
  type: Array<string>;
  active: boolean;
  address: IAddress;
  created_at: Date;
  updated_at: Date;
  company_id?: number;

  admin_id?: number;
  admin?: IUser;
  licenses: Array<ILicences>;
  other_types: Array<string>;
}

export interface ILicences {
  name: string;
  description: string;
  url: string;
  expiration_date: Date;
}

export const blankCompany: ICompany = {
  id: 0,
  business_name: '',
  fantasy_name: '',
  cnpj: '',
  description: '',
  logo: '',
  phone_number: '',
  type: [''],
  active: true,
  address: blankAddress,
  created_at: new Date(),
  updated_at: new Date(),
  company_id: 1,

  admin_id: 0,
  admin: blankUser,
  licenses: [],
  other_types: []
};

export const companyProps = Object.keys(blankCompany);
