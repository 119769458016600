import React from 'react';
import styled from 'styled-components';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, plugins } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, plugins);

const PieDashBoardContainer = styled.div`
  /* margin: auto; */
  width: 25rem;
  height: 25rem;
`;

type PieDashBoardProps = {
  labels?: string[];
  values?: number[];
};

export default function ImpactPie({ labels, values }: PieDashBoardProps) {
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Quantidade do resíduo',
        data: values,
        backgroundColor: ['#A642F4', '#FF6D01', '#34A853', '#FBBC04', '#FF0000', '#00FFFF'],
        hoverOffset: 4
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };

  return (
    <PieDashBoardContainer>
      <Pie
        data={data}
        options={{
          plugins: {
            legend: {
              display: true,
              position: 'right',
              labels: {
                boxWidth: 30,
                boxHeight: 15,
                padding: 50
              }
            }
          }
        }}
      />
    </PieDashBoardContainer>
  );
}
