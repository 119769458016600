import 'dayjs/locale/pt-br';
import './ResiduePage.css';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { MaterialReactTable, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Delete, Download } from '@mui/icons-material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { baseImg } from '../../App';
import { ConfirmModal } from '../../components/ConfirmModal';
import { LoadWrapper, PageContainer, PageContent, PageFooter, PageHeader, PageTitle } from '../../components/GlobalStyleds.jsx';
import PageStructure from '../../components/PageStructure';
import { ICompany } from '../../models/Company';
import { blankResidue, IResidue } from '../../models/Residue';
import ResidueService from '../../services/ResidueService';

export default function ResiduePage() {
  const [modalOpen, setModalOpen] = useState(false);
  const [allResidues, setAllResidues] = useState<IResidue[]>([]);
  const [loading, setLoading] = useState(true);
  const [residue, setResidue] = useState<IResidue>(blankResidue);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const tableInstanceRef = useRef<MRT_TableInstance<IResidue>>(null);
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();

  async function fetchData() {
    setLoading(true);
    let allResidues = await ResidueService.getAll();
    setAllResidues(allResidues);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {}, [residue]);

  //Inefficient

  async function handleSave(residue: IResidue, operation: string) {
    const index = allResidues.findIndex((c) => c.id === residue.id);
    switch (operation) {
      case 'create':
        allResidues.push(residue);
        break;
      case 'update':
        allResidues.splice(index, 1);
        break;
      case 'delete':
        allResidues.splice(index, 1);
        break;
      default:
        break;
    }
    setModalOpen(false);
    setAllResidues([...allResidues]);
    fetchData();
  }

  // Abre o modal de criação de um novo bairro
  function handleNewRow() {
    navigate('residues/0');
  }

  function handleImport() {
    ResidueService.importFromFile();
    setTimeout(() => {
      fetchData();
    }, 300);
  }

  // Exclui o bairro
  function handleDeleteConfirmation() {
    if (Object.keys(rowSelection).length === 0) {
      toast.error('Selecione um bairro para excluir');
      return;
    }
    setShowConfirmationModal(true);
  }

  //handle delete
  async function handleDelete() {
    setLoading(true);
    for (let prop in rowSelection) {
      ResidueService.delete(Number(prop));
    }
    setShowConfirmationModal(false);
    setTimeout(() => {
      fetchData();
    }, 300);
  }

  // Busca o usuário no backend e abre o modal de edição
  async function handleEditRow(row: MRT_Row<IResidue>) {
    navigate(`/residues/${Number(row.id)}`);
  }

  //Export to csv
  function handleExport() {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = allResidues.map((c) => {
      return {
        id: c.id,
        code: c.code,
        description: c.description,
        class: c.class,
        unit: c.unit
      };
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  const columns = useMemo<MRT_ColumnDef<IResidue>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 10
      },
      {
        accessorKey: 'code',
        header: 'Código',
        size: 10,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem'
            }}>
            <Avatar
              sx={{
                height: '25px',
                width: '25px',
                bgcolor: 'transparent'
              }}
              src={row.original.img ? baseImg + row.original.img : ''}>
              -
            </Avatar>

            <span>{renderedCellValue}</span>
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
          </Box>
        )
      },
      {
        accessorKey: 'description',
        header: 'Descrição',
        size: 40
      },
      {
        accessorKey: 'class.name',
        header: 'Classe',
        size: 10
      },
      {
        accessorKey: 'unit.abreviation',
        header: 'Unidade',
        size: 10
      },
      {
        accessorKey: 'state.name',
        header: 'Estado',
        size: 10
      },
      {
        accessorKey: 'level',
        header: 'Nível',
        size: 10
      }
    ],

    []
  );
  // Define a custom function to format the date

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>TIPOS DE RESIDUOS</PageTitle>
        </PageHeader>
        <PageContent>
          {allResidues.length === 0 && (
            <Stack direction="column" sx={{ marginTop: '100px' }} justifyContent="center" alignItems="center" spacing={0}>
              <Typography variant="h4">Nenhum tipo de residuo encontrado</Typography>

              <PersonSearchIcon sx={{ fontSize: 120 }} />

              <Button
                size="medium"
                sx={{
                  width: 400,
                  height: 50
                }}
                color="primary"
                onClick={handleNewRow}
                variant="contained">
                NOVO TIPO DE RESIDUO
              </Button>
              <Button
                size="medium"
                sx={{
                  width: 400,
                  height: 50
                }}
                color="primary"
                onClick={handleImport}
                variant="contained">
                Importar Dados de Arquivo
              </Button>
            </Stack>
          )}
          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {allResidues.length > 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={allResidues}
              enableRowSelection
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false } }}
              renderTopToolbarCustomActions={({ table }) => (
                <Stack direction="row" spacing={1}>
                  <Button color="primary" onClick={handleNewRow} variant="contained">
                    NOVO TIPO DE RESIDUO
                  </Button>
                  <Button color="primary" onClick={handleDeleteConfirmation} variant="contained" startIcon={<Delete />}>
                    Excluir
                  </Button>
                  <Button color="secondary" onClick={handleExport} startIcon={<Download />} variant="contained">
                    Exportar
                  </Button>
                </Stack>
              )}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  handleEditRow(row);
                }
              })}
            />
          )}
          <ConfirmModal
            title="Deleção de tipos de residuo"
            message="Deseja excluir o(s) tipo(s) de residuo(s)?"
            isOpen={showConfirmationModal}
            onConfirm={handleDelete}
            onCancel={() => setShowConfirmationModal(false)}
          />
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
