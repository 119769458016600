import { useEffect, useMemo, useState, useRef } from 'react';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_Row, type MRT_TableInstance } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { Delete, Download, Edit } from '@mui/icons-material';
import { ICompany, blankCompany } from '../../models/Company';
import PageStructure from '../../components/PageStructure';
// import { CompanyModal } from './CompanyModal';
import { PageContainer, PageContent, PageHeader, PageFooter, PageTitle, LoadWrapper } from '../../components/GlobalStyleds.jsx';
import CompanyService from '../../services/CompanyService';
import ReactLoading from 'react-loading';
import './CompanyPage.css';
import { toast } from 'react-toastify';
import { ConfirmModal } from '../../components/ConfirmModal';
// import { CardsContainer } from '../../components/Cards/CardsContainer';
import { ptBR } from '@mui/x-date-pickers/locales';
import { Box, Avatar, ListItemIcon, MenuItem, Typography, Stack, Button } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Grid } from '@mui/material';
import 'dayjs/locale/pt-br';
import ListItemText from '@mui/material/ListItemText';
import { formatDate } from '../../utils/MyLib';
import { getAuthUser } from '../../services/auth';

import { mkConfig, generateCsv, download } from 'export-to-csv';
import { baseImg } from '../../App';
import CardsContainer from '../../components/CardsContainer';
import { IUser } from '../../models/User';
export default function CompanyPage() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [companiesData, setCompaniesData] = useState<ICompany[]>([]);
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<ICompany>(blankCompany);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir o empresa?');
  const [cardChoiced, setCardChoiced] = useState(0);
  const navigate = useNavigate();
  const tableInstanceRef = useRef<MRT_TableInstance<ICompany>>(null);
  const [rowSelection, setRowSelection] = useState({});
  let allCompanies: ICompany[] = [];
  CompanyService.getAll().then((result) => {
    allCompanies = result;
  });

  const [cards, setCards] = useState([
    { id: 0, title: 'Empresas Ativas', content: 0 },
    { id: 1, title: 'Coletadoras', content: 0 },
    { id: 2, title: 'Eco Pontos', content: 0 },
    { id: 3, title: 'Selos Siri', content: 0 },
    { id: 4, title: 'Patrocinadoras', content: 0 },
    { id: 5, title: 'Orgão Público', content: 0 }
  ]);

  async function fetchData() {
    setLoading(true);
    CompanyService.getAll().then((result) => {
      let allCompanies = result;
      setCompaniesData(allCompanies);
      setCompany(blankCompany);
      setLoading(false);
      cards[0].content = allCompanies.length;
      cards[1].content = allCompanies.filter((c) => c.type.includes('Coletadora')).length;
      cards[2].content = allCompanies.filter((c) => c.type.includes('Ecoponto')).length;
      cards[3].content = allCompanies.filter((c) => c.type.includes('Geradora')).length;
      cards[4].content = allCompanies.filter((c) => c.type.includes('Patrocinadora')).length;
      cards[5].content = allCompanies.filter((c) => c.type.includes('Orgão Público')).length;

      setCards([...cards]);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  // Function called when the modal closes with confirm button
  function handleSave(company: ICompany, operation: string): void {
    // const index = companiesData.findIndex((c) => c.id === company.id);
    // switch (operation) {
    //   case 'create':
    //     companiesData.push(company);
    //     break;
    //   case 'update':
    //     companiesData.splice(index, 1);
    //     break;
    //   case 'delete':
    //     companiesData.splice(index, 1);
    //     break;
    //   default:
    //     break;
    // }
    // setCreateModalOpen(false);
    // setCompaniesData([...companiesData]);
    // fetchData();
    window.location.reload();
  }

  // Abre o modal de criação de um novo empresa
  function handleNewRow() {
    navigate('/companies/0');
  }

  // Busca o empresa no backend e abre o modal de edição
  async function handleEditRow(row: MRT_Row<ICompany>) {
    // setLoading(true);
    // CompanyService.getById(row.getValue('id')).then((result) => {
    //   setCompany(result);
    //   setLoading(false);
    //   setCreateModalOpen(true);
    // });
    navigate('/companies/' + row.getValue('id'));
  }

  // Exclui o empresa
  function handleDelete() {
    // setShowConfirmationModal(false);
    // setLoading(true);
    // CompanyService.delete(company.id).then((result) => {
    //   setLoading(false);
    //   const index = companiesData.findIndex((c) => c.id === company.id);
    //   companiesData.splice(index, 1);
    // });
  }

  // Exclui o empresa
  function handleDeleteConfirmation(row: MRT_Row<ICompany>) {
    // setConfirmMessage('Deseja excluir o empresa ' + row.getValue('name') + '?');
    // setShowConfirmationModal(true);
  }

  //TODO: Implementar exportação
  function handleExport() {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = companiesData.map((c) => {
      return {
        id: c.id,
        fantasy_name: c.fantasy_name,
        // email: c.email,
        phone_number: c.phone_number,
        cnpj: c.cnpj,
        created_at: c.created_at
      };
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  async function handleActive(e: any) {
    for (var prop in rowSelection) {
      let company = await CompanyService.getById(Number(prop));
      company.active = e.target.name === 'btnActive' ? true : false;
      await CompanyService.update(company);
    }
    fetchData();
  }

  async function handleClickFilterCard(id: number) {
    let result = allCompanies;
    result = id === 1 ? result.filter((c) => c.type.includes('Coletadora')) : result;
    result = id === 2 ? result.filter((c) => c.type.includes('Ecoponto')) : result;
    result = id === 3 ? result.filter((c) => c.type.includes('Geradora')) : result;
    result = id === 4 ? result.filter((c) => c.type.includes('Patrocinadora')) : result;
    result = id === 5 ? result.filter((c) => c.type.includes('Orgão Público')) : result;
    setCompaniesData(result);
    setCardChoiced(id);
  }

  const columns = useMemo<MRT_ColumnDef<ICompany>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 5
      },
      {
        accessorKey: 'phone_number',
        header: 'Telefone'
      },

      {
        accessorKey: 'active',
        header: 'Status',
        Cell: ({ row }) => (row.original.active ? <CheckCircleOutlineSharpIcon sx={{ color: 'green' }} /> : <span />),
        size: 20
      },

      {
        accessorKey: 'fantasy_name',
        id: 'fantasy_name', //id is still required when using accessorFn instead of accessorKey
        header: 'Nome Fantasia',
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem'
            }}>
            <Avatar
              sx={{
                height: '30px',
                width: '30px'
              }}
              src={row.original.logo ? baseImg + row.original.logo : ''}
            />

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        )
      },
      {
        accessorKey: 'address.city',
        header: 'Cidade'
      },
      {
        accessorKey: 'address.state',
        header: 'State',
        size: 20
      },

      {
        accessorKey: 'type',
        header: 'Tipo'
      }
      // {
      //   accessorFn: (row) => new Date(row.created_at), //convert to Date for sorting and filtering
      //   id: 'created_at',
      //   size: 100,
      //   header: 'Data de início',
      //   filterFn: 'lessThanOrEqualTo',
      //   sortingFn: 'datetime',
      //   Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
      //   Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      //   //Custom Date Picker Filter from @mui/x-date-pickers
      //   Filter: ({ column }) => (
      //     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt" localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
      //       <DatePicker
      //         onChange={(newValue) => {
      //           column.setFilterValue(newValue);
      //         }}
      //         slotProps={{
      //           textField: {
      //             helperText: 'Data menor que:',
      //             sx: { minWidth: '120px' },
      //             variant: 'standard'
      //           }
      //         }}
      //         value={column.getFilterValue()}
      //       />
      //     </LocalizationProvider>
      //   )
      // }
    ],

    []
  );
  // Define a custom function to format the date

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>CENTRAL DE EMPRESAS</PageTitle>
          <CardsContainer
            cards={cards}
            choiced={cardChoiced}
            onChange={(id) => {
              handleClickFilterCard(id);
            }}></CardsContainer>
        </PageHeader>
        <PageContent>
          {companiesData.length === 0 && (
            <Stack direction="column" sx={{ marginTop: '100px' }} justifyContent="center" alignItems="center" spacing={0}>
              <Typography variant="h4">Nenhum empresa encontrado</Typography>

              <PersonSearchIcon sx={{ fontSize: 120 }} />

              <Button
                size="medium"
                sx={{
                  width: 300,
                  height: 50
                }}
                color="primary"
                onClick={handleNewRow}
                variant="contained"
                startIcon={<AccountCircle />}>
                Novo
              </Button>
            </Stack>
          )}

          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {companiesData.length > 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={companiesData}
              enableRowSelection
              // enableRowActions
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false, phone_number: false, cpf: false } }}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  handleEditRow(row);
                }
              })}
              // renderRowActionMenuItems={({ row }) => [
              //   <MenuItem
              //     dense
              //     key={0}
              //     onClick={() => {
              //       // View profile logic...
              //       handleEditRow(row);
              //     }}
              //     sx={{ m: 0 }}>
              //     <ListItemIcon>
              //       <Edit />
              //     </ListItemIcon>
              //     Editar empresa
              //   </MenuItem>,
              //   <MenuItem
              //     dense
              //     key={1}
              //     onClick={() => {
              //       // Send email logic...
              //       handleDeleteConfirmation(row);
              //     }}
              //     sx={{ m: 0 }}>
              //     <ListItemIcon>
              //       <Delete />
              //     </ListItemIcon>
              //     Excluir empresa
              //   </MenuItem>
              // ]}
              renderTopToolbarCustomActions={({ table }) => (
                <Stack direction="row" spacing={1}>
                  <Button color="primary" onClick={handleNewRow} variant="contained" startIcon={<AccountCircle />}>
                    Novo
                  </Button>
                  <Button name="btnActive" color="secondary" onClick={handleActive} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Ativar
                  </Button>
                  <Button name="btnInactive" color="secondary" onClick={handleActive} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Desativar
                  </Button>
                  {/* <Link to="/companies">
                    <Button color="secondary" variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                      Exibir empresas vinculados
                    </Button>
                  </Link> */}
                  <Button color="secondary" onClick={handleExport} startIcon={<Download />} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Exportar
                  </Button>
                </Stack>
              )}
              renderDetailPanel={({ row }) => (
                <Grid container rowSpacing={0} alignItems="center">
                  {/* 1 */}
                  <Grid item xs={1} alignItems="center" justifyContent="center">
                    <Avatar sx={{ height: 70, width: 70 }} src={row.original.logo ? baseImg + row.original.logo : ''} />
                  </Grid>
                  <Grid item xs={4} alignContent="left" sx={{ marginBottom: 2, paddingRight: 2 }}>
                    <ListItemText primaryTypographyProps={{ style: { fontSize: '1.1rem', fontWeight: 'bold', wordWrap: 'break-word' } }} primary={row.original.fantasy_name} />
                  </Grid>
                  <Grid item xs={1} alignContent="left">
                    <ListItemText
                      secondaryTypographyProps={{ style: { fontSize: '1.1rem', color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      secondary={row.original.id}
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText
                      secondary="Data criação"
                      primaryTypographyProps={{ style: { color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      primary={formatDate(row.original.created_at)}
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText
                      secondary="Última Edição"
                      primaryTypographyProps={{ style: { color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      primary={formatDate(row.original.updated_at)}
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText
                      secondary="Status"
                      primary={row.original.active ? 'Ativo' : 'Inativo'}
                      primaryTypographyProps={{ style: { color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    />
                  </Grid>
                  {/* 2 */}
                  <Grid item xs={2.4} alignContent="left">
                    <ListItemText secondary="Razão Social" primary={row.original.business_name} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2.4} alignContent="left">
                    <ListItemText secondary="Telefone" primary={row.original.phone_number} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2.4} alignContent="left">
                    <ListItemText secondary="Ramo" primary={row.original.description} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2.4} alignContent="left">
                    <ListItemText secondary="Cep" primary={row.original.address.zip_code} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2.4} alignContent="left">
                    <ListItemText secondary="Cidade" primary={row.original.address.city} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  {/* 3 */}
                  {/* <Grid item xs={2.4} alignContent="left">
                    <ListItemText secondary="Email" primary={row.original.email} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid> */}
                  <Grid item xs={2.4} alignContent="left">
                    <ListItemText secondary="CNPJ" primary={row.original.cnpj} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2.4} alignContent={'left'}>
                    <ListItemText secondary="Empresas vinculados" primary={row.original.address.city} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2.4} alignContent="left">
                    <ListItemText secondary="Endereço" primary={row.original.address.street} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2.4} alignContent="left">
                    <ListItemText secondary="N°" primary={row.original.address.number} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                </Grid>
              )}
            />
          )}

          {/* <CompanyModal value={company} open={createModalOpen} onClose={() => setCreateModalOpen(false)} onSubmit={handleSave} /> */}
          <ConfirmModal title="Deleção de Empresas" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
