import React from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Grid } from '@mui/material';

//Svg's
import { ReactComponent as Siri } from '../../assets/dashboardIcons/siri.svg';
import { ReactComponent as Vilarejo } from '../../assets/dashboardIcons/logo_vilarejo1.svg';
import { ReactComponent as Tree } from '../../assets/dashboardIcons/arvore.svg';
import { ReactComponent as Industry } from '../../assets/dashboardIcons/co2.svg';
import { ReactComponent as Energy } from '../../assets/dashboardIcons/energia.svg';
import { ReactComponent as Watter } from '../../assets/dashboardIcons/agua.svg';
import { ReactComponent as IlustraSiri } from '../../assets/dashboardIcons/ilustra_siri 1.svg';
import googleplay from '../../assets/googleplay.png';
import appstore from '../../assets/appstore.png';
import { ReactComponent as QrCode } from '../../assets/dashboardIcons/qr_code 1.svg';

import ImpactPie from '../../components/ImpactPie';
import ImpactBar from '../../components/ImpactBar';
import ImpactCard from '../../components/ImpactCard';

import { IPositiveImpact } from '../../models/PositiveImpact';
import { ICollectTreatment } from '../../models/CollectTreatment';
import CollectTreatmentService from '../../services/CollectTreatmentService';
import { useParams } from 'react-router-dom';
import CollectResiduesService from '../../services/CollectResiduesService';

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #d3f3ca;

  ${css`
    @media (min-width: 1440px) {
      width: 1440px;
    }
  `}
`;

// Header
const HeaderContainer = styled.div`
  margin: auto;
  display: flex;
  gap: 10px;
  width: 95%;
  padding: 10px;
  margin-bottom: 2rem;
  min-height: 10vh;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SiriLogo = styled(Siri)`
  width: 56px;
  height: 56px;
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderText = styled.p`
  text-transform: none;
  line-height: 1.2rem;
`;
const HeaderMainText = styled.h3`
  text-transform: none;
  line-height: 1.2rem;
`;

// Main Content
const MainContent = styled.div`
  margin: auto;
  width: 95%;
  min-height: 80vh;
`;

const MainText = styled.h5`
  font-size: 0.75rem;
  text-transform: uppercase;
`;

const MainGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  height: 400px;
  border-radius: 4px;
  box-shadow: var(--shadow-2);
`;

const FristGridInitialContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FristGridTextContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const SecondGridTextContainer = styled.div`
  padding: 10px;
`;

const FristMainText = styled.h1`
  line-height: 2rem;
  text-transform: none;
`;

const VilarejoLogoContainer = styled.div`
  display: flex;
  padding-right: 10px;
  justify-content: flex-end;
  width: 50%;
`;

const ImpactCardsContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

// Footer
const FooterContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding: 20px 0;
  min-height: 10vh;
`;

const FooterInicialContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FooterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1.3rem;
`;

const OurServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.4rem;
`;

const OurServicesTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const AppsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const StoreIcons = styled.img`
  width: 140px;
  height: 40px;
`;

const QrCodeContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const QrTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FooterText = styled.p`
  text-transform: none;
  line-height: 1.2rem;
  font-weight: 700;
`;

const FooterMainText = styled.h3`
  text-transform: none;
  line-height: 1.2rem;
`;

const impactCards = [
  {
    id: 1,
    impact: 0,
    subText: 'Árvores Desmatadas (unidade)',
    img: Tree
  },
  {
    id: 3,
    impact: 0,
    subText: 'carbono emitido (CO²-Kg)',
    img: Industry
  },
  {
    id: 4,
    impact: 0,
    subText: 'energia consumida (Kwh)',
    img: Energy
  },
  {
    id: 2,
    impact: 0,
    subText: 'água utilizada (L)',
    img: Watter
  }
];

interface PieDashBoardInfo {
  title: string;
  value: number;
}

export default function ImpactPage() {
  const { id } = useParams<{ id: string }>();
  const user_id = Number(id);

  const [collectTreatments, setCollectTreatments] = useState<ICollectTreatment[]>([]);
  const [positiveImpact, setPositiveImpact] = useState<IPositiveImpact[]>([]);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [pieLabels, setPieLabels] = useState<string[]>([]);
  const [pieValues, setPieValues] = useState<number[]>([]);
  const [impactCardsInfo, setImpactCardsInfo] = useState(impactCards);
  const [totalCollectedByMonth, setTotalCollectedByMonth] = useState<number[]>([]);
  const [totalRecycledByMonth, setTotalRecycledByMonth] = useState<number[]>([]);
  const [totalCompostByMonth, setTotalCompostByMonth] = useState<number[]>([]);

  async function getPositiveImpact() {
    const collectTreatmentsInfo = await CollectTreatmentService.getById(user_id);
    const collectResidues = await CollectResiduesService.getById(user_id);

    setCollectTreatments(collectTreatmentsInfo);
    console.log(collectTreatmentsInfo);
    console.log(collectResidues);

    const totalAvoidCO2 = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.avoid_co2, 0);
    const totalWaterSaved = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.water_saved, 0);
    const totalEnergySaved = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.energy_saved, 0);

    let mainCardsInfo = impactCards;
    mainCardsInfo[0].impact = parseFloat(totalAvoidCO2.toFixed(2));
    mainCardsInfo[1].impact = parseFloat(totalAvoidCO2.toFixed(2));
    mainCardsInfo[2].impact = parseFloat(totalEnergySaved.toFixed(2));
    mainCardsInfo[3].impact = parseFloat(totalWaterSaved.toFixed(2));

    //Break
    const validTitles = ['Vidro', 'Papel e cartão', 'Plástico', 'Metal'];

    const validCollectTreatments = collectResidues.reduce<PieDashBoardInfo[]>((acc, obj) => {
      let titleTrimmed = '';
      if (obj.title) {
        titleTrimmed = obj.title.trim();
      } else {
        titleTrimmed = 'Outros';
      }
      if (validTitles.includes(titleTrimmed)) {
        acc.push({ title: titleTrimmed, value: obj.value });
      } else {
        if (!acc.some((item) => item.title === 'Outros')) {
          acc.push({ title: 'Outros', value: 0 });
        }
        acc.find((item) => item.title === 'Outros')!.value += obj.value;
      }

      return acc;
    }, []);

    const totalWeight = validCollectTreatments.reduce((acc, item) => acc + item.value, 0);
    const formatedTotalWeight = parseFloat(totalWeight.toFixed(2));

    setTotalWeight(formatedTotalWeight);
    setPieLabels(validCollectTreatments.map((item) => item.title));
    setPieValues(validCollectTreatments.map((item) => item.value));
    setImpactCardsInfo(mainCardsInfo);
  }

  async function getPositiveImpactByMonth() {
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const currentYear = new Date().getFullYear();
    const inicialDate = `01`;
    const endDate = `31`;
    const totalCollectedByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const totalRecycledByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const totalCompostByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const collectTreatmentsInfoJanuary = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-01-${inicialDate}`, `${currentYear}-01-${endDate}`);
    const collectTreatmentsInfoFebruary = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-02-${inicialDate}`, `${currentYear}-02-${endDate}`);
    const collectTreatmentsInfoMarch = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-03-${inicialDate}`, `${currentYear}-03-${endDate}`);
    const collectTreatmentsInfoApril = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-04-${inicialDate}`, `${currentYear}-04-${endDate}`);
    const collectTreatmentsInfoMay = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-05-${inicialDate}`, `${currentYear}-05-${endDate}`);
    const collectTreatmentsInfoJune = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-06-${inicialDate}`, `${currentYear}-06-${endDate}`);
    const collectTreatmentsInfoJuly = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-07-${inicialDate}`, `${currentYear}-07-${endDate}`);
    const collectTreatmentsInfoAugust = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-08-${inicialDate}`, `${currentYear}-08-${endDate}`);
    const collectTreatmentsInfoSeptember = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-09-${inicialDate}`, `${currentYear}-09-${endDate}`);
    const collectTreatmentsInfoOctober = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-10-${inicialDate}`, `${currentYear}-10-${endDate}`);
    const collectTreatmentsInfoNovember = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-11-${inicialDate}`, `${currentYear}-11-${endDate}`);
    const collectTreatmentsInfoDecember = await CollectTreatmentService.getByMonth(user_id, `${currentYear}-12-${inicialDate}`, `${currentYear}-12-${endDate}`);

    totalCollectedByMonth[0] = collectTreatmentsInfoJanuary.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[1] = collectTreatmentsInfoFebruary.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[2] = collectTreatmentsInfoMarch.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[3] = collectTreatmentsInfoApril.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[4] = collectTreatmentsInfoMay.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[5] = collectTreatmentsInfoJune.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[6] = collectTreatmentsInfoJuly.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[7] = collectTreatmentsInfoAugust.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[8] = collectTreatmentsInfoSeptember.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[9] = collectTreatmentsInfoOctober.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[10] = collectTreatmentsInfoNovember.reduce((acc, item) => acc + item.value, 0);
    totalCollectedByMonth[11] = collectTreatmentsInfoDecember.reduce((acc, item) => acc + item.value, 0);

    console.log(collectTreatmentsInfoMay);

    //Break
    if (collectTreatmentsInfoJanuary) {
      collectTreatmentsInfoJanuary.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[0] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[0] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoFebruary) {
      collectTreatmentsInfoFebruary.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[1] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[1] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoMarch) {
      collectTreatmentsInfoMarch.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[2] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[2] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoApril) {
      collectTreatmentsInfoApril.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[3] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[3] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoMay) {
      collectTreatmentsInfoMay.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[4] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          console.log('May');
          totalCompostByMonth[4] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoJune) {
      collectTreatmentsInfoJune.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[5] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[5] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoJuly) {
      collectTreatmentsInfoJuly.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[6] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[6] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoAugust) {
      collectTreatmentsInfoAugust.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[7] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[7] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoSeptember) {
      collectTreatmentsInfoSeptember.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[8] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[8] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoOctober) {
      collectTreatmentsInfoOctober.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[9] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[9] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoNovember) {
      collectTreatmentsInfoNovember.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[10] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[10] = treatment.value;
        }
      });
    }
    if (collectTreatmentsInfoDecember) {
      collectTreatmentsInfoDecember.map((treatment) => {
        if (treatment.title === 'Reciclado') {
          totalRecycledByMonth[11] = treatment.value;
        }
        if (treatment.title === 'Compostagem') {
          totalCompostByMonth[11] = treatment.value;
        }
      });
    }

    console.log(totalCollectedByMonth);
    console.log(totalRecycledByMonth);
    console.log(totalCompostByMonth);

    setTotalCollectedByMonth(totalCollectedByMonth);
    setTotalRecycledByMonth(totalRecycledByMonth);
    setTotalCompostByMonth(totalCompostByMonth);
  }

  useEffect(() => {
    getPositiveImpact();
    getPositiveImpactByMonth();
  }, []);

  return (
    <MainContainer>
      <HeaderContainer>
        <LogoContainer>
          <SiriLogo />
        </LogoContainer>
        <HeaderTextContainer>
          <HeaderText>Os impactos que transforman</HeaderText>
          <HeaderMainText>Você fez parte disso!</HeaderMainText>
        </HeaderTextContainer>
      </HeaderContainer>

      <MainContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <MainText>ações que transformam o mundo:</MainText>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <MainGraphContainer>
              <FristGridInitialContainer>
                <FristGridTextContainer>
                  <MainText>total de resíduos recicláveis destinados</MainText>
                  <FristMainText>{totalWeight}kg</FristMainText>
                </FristGridTextContainer>
                <VilarejoLogoContainer>
                  <Vilarejo width={160} height={80} />
                </VilarejoLogoContainer>
              </FristGridInitialContainer>
              <ImpactBar data_1={totalCollectedByMonth} data_2={totalRecycledByMonth} data_3={totalCompostByMonth} />
            </MainGraphContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <MainGraphContainer>
              <SecondGridTextContainer>
                <MainText>total destinado separado por resíduo</MainText>
                <ImpactPie labels={pieLabels} values={pieValues} />
              </SecondGridTextContainer>
            </MainGraphContainer>
          </Grid>
        </Grid>
        <MainText
          style={{
            marginTop: '1rem'
          }}>
          as nossas ações evitam que:
        </MainText>
        <ImpactCardsContainer>
          <Grid container spacing={1}>
            {impactCardsInfo.map((card) => (
              <Grid item xs={6} sm={6} md={3} key={card.id}>
                <ImpactCard impact={card.impact} text={card.subText} Icon={card.img} />
              </Grid>
            ))}
          </Grid>
        </ImpactCardsContainer>
      </MainContent>

      <FooterContainer>
        {/* Init */}
        <FooterInicialContainer>
          <IlustraSiri width={176} height={100} />
          <FooterTextContainer>
            <FooterText>A reciclagem é feita</FooterText>
            <FooterText>em parceiria com a Sirí</FooterText>
          </FooterTextContainer>
        </FooterInicialContainer>
        {/* Our Service */}
        <OurServicesContainer>
          <OurServicesTextContainer>
            <FooterMainText>Conheça as nossas</FooterMainText>
            <FooterMainText>redes, serviços e APP!</FooterMainText>
          </OurServicesTextContainer>
          <AppsContainer>
            <a href="https://play.google.com/store/apps/details?id=com.fluxo.siri">
              <StoreIcons alt="Get it on Google Play" src={googleplay} />
            </a>
            <a href="https://apps.apple.com">
              <StoreIcons alt="Download on the App Store" src={appstore} />
            </a>
          </AppsContainer>
        </OurServicesContainer>
        {/* Qr */}
        <QrCodeContainer>
          <QrTextContainer>
            <FooterText>
              Escaneie o <b>Qr Code</b> e veja
            </FooterText>
            <FooterText>o relatório em tempo real</FooterText>
          </QrTextContainer>
          <QrCode width={100} height={100} />
        </QrCodeContainer>
      </FooterContainer>
    </MainContainer>
  );
}

// const hhh = [
//    {
//     "title": "Compostagem",
//     "value": "193,89",
//     "id": "1",
//     "link": "link tratamento",
//     "cod_inea": 0,
//     "percent": 100,
//     "residues": [
//       {
//         "title": "Vidro",
//         "id": 2,
//         "valor": 21.6,
//         "porcentagem": 11.139762764311504
//       },
//       {
//         "title": "Metais",
//         "id": 30,
//         "valor": 20,
//         "porcentagem": 10.31459515214028
//       },
//       {
//         "title": "Papel e cartão",
//         "id": 4,
//         "valor": 152.3,
//         "porcentagem": 78.54564208354824
//       }
//     ],
//     "positive_impact": {
// 			"price": 581.6999999999999,
// 			"avoid_co2": 969.4999999999999,
// 			"oil_saved": 387.79999999999995,
// 			"ref_price": ".",
// 			"water_saved": 775.5999999999999,
// 			"energy_saved": 581.6999999999999,
// 			"mineral_name": ".",
// 			"ref_avoid_co2": ".",
// 			"ref_oil_saved": ".",
// 			"minerals_saved": 193.89999999999998,
// 			"ref_water_saved": ".",
// 			"ref_energy_saved": ".",
// 			"ref_minerals_saved": "."
// 		},
//   }
// ]
