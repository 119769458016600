import { Delete, Download } from '@mui/icons-material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import { Button, Stack, Typography } from '@mui/material';
import 'dayjs/locale/pt-br';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_TableInstance, type MRT_Row } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmModal } from '../../components/ConfirmModal';
import { LoadWrapper, PageContainer, PageContent, PageFooter, PageHeader, PageTitle } from '../../components/GlobalStyleds.jsx';
import PageStructure from '../../components/PageStructure';
import { IResidueClass } from '../../models/ResidueClass';
import ResidueClassService from '../../services/ResidueClassService';
import { getAuthUser } from '../../services/auth';

export default function ResidueClassPage() {
  const [allResidueClasss, setAllResidueClasss] = useState<IResidueClass[]>([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const tableInstanceRef = useRef<MRT_TableInstance<IResidueClass>>(null);
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();

  async function fetchData() {
    setLoading(true);
    let allResidueClasss = await ResidueClassService.getAll();
    setAllResidueClasss(allResidueClasss);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function handleNew() {
    navigate('/residue_classes/0');
    fetchData();
  }

  // Exclui o bairro
  function handleDeleteConfirmation() {
    if (Object.keys(rowSelection).length === 0) {
      toast.error('Selecione um Classe de Residuos para excluir');
      return;
    }
    setShowConfirmationModal(true);
  }

  // Busca o usuário no backend e abre o modal de edição
  async function handleEditRow(row: MRT_Row<IResidueClass>) {
    navigate(`/residue_classes/${Number(row.id)}`);
  }

  //handle delete
  function handleDelete() {
    setLoading(true);
    for (let prop in rowSelection) {
      ResidueClassService.delete(Number(prop));
    }
    setShowConfirmationModal(false);
    setTimeout(() => {
      fetchData();
    }, 300);
  }

  // Export to csv
  function handleExport() {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = allResidueClasss.map((c) => {
      return {
        id: c.id,
        name: c.name,
        codInea: c.codInea
      };
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  const columns = useMemo<MRT_ColumnDef<IResidueClass>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 5
      },
      {
        accessorKey: 'name',
        header: 'Descrição'
      },

      {
        accessorKey: 'codInea',
        header: 'Código INEA'
      }
    ],

    []
  );
  // Define a custom function to format the date

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>CLASSE DE RESÍDUO</PageTitle>
        </PageHeader>
        <PageContent>
          {allResidueClasss.length === 0 && (
            <Stack direction="column" sx={{ marginTop: '100px' }} justifyContent="center" alignItems="center" spacing={0}>
              <Typography variant="h4">Nenhuma classe de resíduo encontrada</Typography>

              <PersonSearchIcon sx={{ fontSize: 120 }} />

              <Button
                size="medium"
                sx={{
                  width: 400,
                  height: 50
                }}
                color="primary"
                onClick={handleNew}
                variant="contained">
                NOVA Classe de Residuo
              </Button>
            </Stack>
          )}
          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {allResidueClasss.length > 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={allResidueClasss}
              enableRowSelection
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false } }}
              renderTopToolbarCustomActions={({ table }) => (
                <Stack direction="row" spacing={1}>
                  <Button color="primary" onClick={handleNew} variant="contained">
                    NOVO SERVIÇO
                  </Button>
                  <Button color="primary" onClick={handleDeleteConfirmation} variant="contained" startIcon={<Delete />}>
                    Excluir
                  </Button>
                  <Button color="secondary" onClick={handleExport} startIcon={<Download />} variant="contained">
                    Exportar
                  </Button>
                </Stack>
              )}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  handleEditRow(row);
                }
              })}
            />
          )}

          <ConfirmModal
            title="Deleção de Classe de Residuos(s)"
            message="Deseja excluir a(s) Classe de Residuos(s)?"
            isOpen={showConfirmationModal}
            onConfirm={handleDelete}
            onCancel={() => setShowConfirmationModal(false)}
          />
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
