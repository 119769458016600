import React from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Grid } from '@mui/material';
import DashboardCard from '../../components/DashboardCard';
import DashboardCardSecond from '../../components/DashboardCardSecond';
import googleplay from '../../assets/googleplay.png';
import appstore from '../../assets/appstore.png';

import { ReactComponent as Instagram } from '../../assets/Insta.svg';
import { ReactComponent as Agua } from '../../assets/dashboardIcons/ico_report-01.svg';
import { ReactComponent as Energy } from '../../assets/dashboardIcons/ico_report-02.svg';
import { ReactComponent as Oleo } from '../../assets/dashboardIcons/ico_report-03.svg';
import { ReactComponent as Tree } from '../../assets/dashboardIcons/ico_report-04.svg';
import { ReactComponent as Rock } from '../../assets/dashboardIcons/ico_report-05.svg';

import { IPositiveImpact } from '../../models/PositiveImpact';
import { ICollectTreatment } from '../../models/CollectTreatment';
import CollectTreatmentService from '../../services/CollectTreatmentService';
import { title } from 'process';
import { useParams } from 'react-router-dom';
import CollectResiduesService from '../../services/CollectResiduesService';

const MainContainer = styled.div`
  max-width: 90rem;
  height: 100vh;
  margin: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

const HeaderTitle = styled.h1`
  font-weight: 400;
  font-size: 2.5rem;

  ${css`
    @media (max-width: 500px) {
      font-size: 1.8rem;
    }

    @media (max-width: 400px) {
      font-size: 1.5rem;
    }
  `}
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 60vh;
  background: #70b6da;
`;

const FristTextContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const FristText = styled.h1`
  color: white;
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: none;

  ${css`
    @media (max-width: 500px) {
      font-size: 1.8rem;
    }

    @media (max-width: 400px) {
      font-size: 1.5rem;
    }
  `}
`;

const SecondTextContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const SecondText = styled.h1`
  color: white;
  font-size: 2.5rem;
  text-transform: none;

  ${css`
    @media (max-width: 500px) {
      font-size: 1.8rem;
    }

    @media (max-width: 400px) {
      font-size: 1.5rem;
    }
  `}
`;

const AppsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;
`;
const InstagramContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;
`;

const StoreIcons = styled.img`
  @media (max-width: 500px) {
    width: 150px;
    height: 45px;
  }
`;

const InstagramLogo = styled(Instagram)`
  width: 70px;
  height: 70px;

  @media (max-width: 500px) {
    width: 50px;
    height: 50px;
  }
`;

// async function fetchData() {
//   console.log('fetchData');
// }

// useEffect(() => {
//   fetchData();
// }, []);

const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

//metros cubicos de agua m
const calcInfo = [
  {
    title: 'Papel & Papelão',
    weight: 0,
    water: 0,
    trees: 0,
    oil: 0,
    energy: 0
  },
  {
    title: 'Metal',
    weight: 0,
    water: 0,
    energy: 0,
    oil: 0
  },
  {
    title: 'Vidro',
    weight: 0,
    water: 0,
    energy: 0
  },
  {
    title: 'Plástico',
    weight: 0,
    minerals: 0,
    energy: 0
  }
];

interface CardsInfo {
  title: string;
  value: number;
}

export default function Dashboard() {
  const { id } = useParams<{ id: string }>();
  const user_id = Number(id);

  const [collectTreatments, setCollectTreatments] = useState<ICollectTreatment[]>([]);
  const [positiveImpact, setPositiveImpact] = useState<IPositiveImpact[]>([]);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [calcCardsInfo, setCalcCardsInfo] = useState(calcInfo);

  async function getPositiveImpact() {
    const collectTreatmentsInfo = await CollectTreatmentService.getById(user_id);
    const collectResidues = await CollectResiduesService.getById(user_id);

    setCollectTreatments(collectTreatmentsInfo);
    console.log(collectTreatmentsInfo);

    const validTitles = ['Vidro', 'Papel e cartão', 'Plástico', 'Metal'];

    const validCollectTreatments = collectResidues.filter((treatment) => {
      if (treatment.title) {
        return validTitles.includes(treatment.title.trim());
      }
    });

    console.log(validCollectTreatments);

    const validCollectTreatmentsValues = validCollectTreatments.map((treatment) => ({ title: treatment.title.trim(), value: treatment.value }));

    const plastic = validCollectTreatmentsValues.find((treatment) => treatment.title === 'Plástico');
    const paper = validCollectTreatmentsValues.find((treatment) => treatment.title === 'Papel e cartão');
    const glass = validCollectTreatmentsValues.find((treatment) => treatment.title === 'Vidro');
    const metal = validCollectTreatmentsValues.find((treatment) => treatment.title === 'Metal');

    const waterImpact = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.water_saved, 0);
    const energyImpact = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.energy_saved, 0);
    const oilImpact = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.oil_saved, 0);
    const mineralsImpact = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.minerals_saved, 0);
    const treesImpact = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.avoid_co2, 0);

    const calc = calcInfo;

    calc[0].weight = parseFloat(paper?.value?.toFixed(2) ?? '0');
    if (calc[0].weight > 0) {
      calc[0].water = parseFloat(waterImpact.toFixed(2));
      calc[0].trees = parseFloat(treesImpact.toFixed(2));
      calc[0].oil = parseFloat(oilImpact.toFixed(2));
      calc[0].energy = parseFloat(energyImpact.toFixed(2));
    }

    calc[1].weight = parseFloat(metal?.value.toFixed(2) ?? '0');
    if (calc[1].weight > 0) {
      calc[1].water = parseFloat(waterImpact.toFixed(2));
      calc[1].energy = parseFloat(energyImpact.toFixed(2));
      calc[1].oil = parseFloat(oilImpact.toFixed(2));
    }

    calc[2].weight = parseFloat(glass?.value.toFixed(2) ?? '0');
    if (calc[2].weight > 0) {
      calc[2].water = parseFloat(waterImpact.toFixed(2));
      calc[2].energy = parseFloat(energyImpact.toFixed(2));
    }

    calc[3].weight = parseFloat(plastic?.value.toFixed(2) ?? '0');
    if (calc[3].weight > 0) {
      calc[3].minerals = parseFloat(mineralsImpact.toFixed(2));
      calc[3].energy = parseFloat(energyImpact.toFixed(2));
    }

    console.log(calc);

    const totalWeight = collectTreatmentsInfo.reduce((acc, item) => acc + item.value, 0);
    const formatedTotalWeight = parseFloat(totalWeight.toFixed(2));

    setCalcCardsInfo(calc);
    setTotalWeight(formatedTotalWeight);
  }

  useEffect(() => {
    getPositiveImpact();
  }, []);

  return (
    <MainContainer>
      <HeaderContainer>
        <HeaderTitle>Os impactos ecológicos</HeaderTitle>
        <HeaderTitle>
          do mês de <strong>{`${monthNames[currentMonth]} de ${currentYear}`}</strong>
        </HeaderTitle>
      </HeaderContainer>
      <DashboardCard weight={totalWeight} subText="COLETADO NO TOTAL" color="#00A228" />
      <DashboardCardSecond weight={calcCardsInfo[0].weight} residue="papel & papelao" impact={calcCardsInfo[0]} color="#4F82C1" cardNumber={0} />
      <DashboardCardSecond weight={calcCardsInfo[1].weight} residue="metal" impact={calcCardsInfo[1]} color="#FBBC00" cardNumber={1} />
      <DashboardCardSecond weight={calcCardsInfo[2].weight} residue="vidro" impact={calcCardsInfo[2]} color="#97A228" cardNumber={2} />
      <DashboardCardSecond weight={calcCardsInfo[3].weight} residue="plástico" impact={calcCardsInfo[3]} color="#B93435" cardNumber={3} />
      <FooterContainer>
        <FristTextContainer>
          <FristText>A coleta Seletiva e feita</FristText>
          <FristText>
            em parceria com a <b>Siri</b>.
          </FristText>
        </FristTextContainer>
        <SecondTextContainer>
          <SecondText>Conheca as nossas</SecondText>
          <SecondText>redes, servicos e App</SecondText>
        </SecondTextContainer>
        <AppsContainer>
          <a href="https://play.google.com/store/apps/details?id=com.fluxo.siri">
            <StoreIcons alt="Get it on Google Play" src={googleplay} />
          </a>
          <a href="https://apps.apple.com">
            <StoreIcons alt="Download on the App Store" src={appstore} />
          </a>
        </AppsContainer>
        <InstagramContainer>
          <InstagramLogo />
        </InstagramContainer>
      </FooterContainer>
    </MainContainer>
  );
}
