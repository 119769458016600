import { Grid } from '@mui/material';
import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as Agua } from '../assets/dashboardIcons/ico_report-01.svg';
import { ReactComponent as Energy } from '../assets/dashboardIcons/ico_report-02.svg';
import { ReactComponent as Oleo } from '../assets/dashboardIcons/ico_report-03.svg';
import { ReactComponent as Tree } from '../assets/dashboardIcons/ico_report-04.svg';
import { ReactComponent as Rock } from '../assets/dashboardIcons/ico_report-05.svg';

const DashboardContainer = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 5rem;
  max-height: 40rem;
  padding-bottom: 2rem;
  background: ${(props) => props.color};
  border-bottom: 4px solid #ffffff;
  position: relative;
`;

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2.3rem;
  margin-bottom: 1.3rem;
  width: 95%;
`;

const MainText = styled.h1`
  color: #ffffff;
  font-weight: 400;

  ${css`
    @media (max-width: 500px) {
      font-size: 1.8rem;
    }

    @media (max-width: 400px) {
      font-size: 1.5rem;
    }
  `}
`;

const SubText = styled.p`
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
`;

const ImpactContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Triangle = styled.div<{ color: string }>`
  width: 50px;
  height: 50px;
  background: ${(props) => props.color};
  border: 4px solid #ffffff;
  border-top: none;
  border-left: none;
  rotate: 45deg;
  position: absolute;
  bottom: -27.8px;
  z-index: 1;
`;

interface Impact {
  water?: number;
  energy?: number;
  oil?: number;
  minerals?: number;
  trees?: number;
}

interface DashboardCardProps {
  weight?: number;
  residue?: string;
  cardNumber: number;
  impact: Impact;
  color: string;
}

export default function DashboardCardSecond({ weight, residue, color, cardNumber, impact }: DashboardCardProps) {
  const cardInfo = [
    [
      {
        svg: Agua,
        mainText: `economia de ${impact.water}m³`,
        text: 'de água'
      },
      {
        svg: Tree,
        mainText: `${impact.trees} árvores`,
        text: 'plantadas'
      },
      {
        svg: Oleo,
        mainText: `menos de ${impact.oil}`,
        text: 'barris de petróleo'
      },
      {
        svg: Energy,
        mainText: `economia de ${impact.energy}MWH`,
        text: 'em energia'
      }
    ],
    [
      {
        svg: Agua,
        mainText: `economia de ${impact.water}m³`,
        text: 'de água'
      },
      {
        svg: Energy,
        mainText: `economia de ${impact.energy}MWH`,
        text: 'em energia'
      },
      {
        svg: Oleo,
        mainText: `menos de ${impact.oil}`,
        text: 'barris de petróleo'
      }
    ],
    [
      {
        svg: Agua,
        mainText: `economia de ${impact.water}m³`,
        text: 'de água'
      },
      {
        svg: Energy,
        mainText: `economia de ${impact.energy}MWH`,
        text: 'em energia'
      }
    ],
    [
      {
        svg: Rock,
        mainText: 'deixamos de exatrair',
        text: `${impact.minerals}KG de areia`
      },
      {
        svg: Energy,
        mainText: `economia de ${impact.energy}MWH`,
        text: 'em energia'
      }
    ]
  ];

  return (
    <DashboardContainer color={color}>
      <MainContentContainer>
        <MainText>
          <b>{residue}</b> {weight}
        </MainText>
        <Grid container gap={2}>
          {cardInfo[cardNumber]?.map((card, index) => (
            <Grid item xs={12} md={12} key={index}>
              <ImpactContainer>
                <card.svg width={50} height={50} />
                <SubText>
                  <strong>{card.mainText}</strong> {card.text}
                </SubText>
              </ImpactContainer>
            </Grid>
          ))}
          <Grid item xs={12} md={12}></Grid>
        </Grid>
      </MainContentContainer>
      <Triangle color={color} />
    </DashboardContainer>
  );
}
