import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { baseImg } from '../../App';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';
import { ReactComponent as RecicleIcon } from '../../assets/icons/mdi_recycle.svg';
import AddressBox from '../../components/AddressBox';
import CardsContainerModal from '../../components/CardsContainerModal';
import CardsModalWeek from '../../components/CardsModalWeek';
import { ConfirmModal } from '../../components/ConfirmModal';
import { PageContainer } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import SubCardComponent from '../../components/SubCard';
import { BigTextField, MediumTextField, SmallTextField } from '../../components/TextFields';
import { blankAddress } from '../../models/Address';
import { blankCollect, collectStatus, ICollect } from '../../models/Collect';
import { blankCompany, ICompany } from '../../models/Company';
import { blankMaterial, IMaterial } from '../../models/Material';
import { IComposition, IPack } from '../../models/Pack';
import { blankRoute, INeighborhoodSchedule, IRoute } from '../../models/Route';
import { allStatesNames } from '../../models/States';
import { blankUser, IUser } from '../../models/User';
import { getAuthUser, protectForm } from '../../services/auth';
import CollectService from '../../services/CollectService';
import PackService from '../../services/PackService';
import RouteEventsService from '../../services/RouteEventService';
import RouteService from '../../services/RouteService';
import UserService from '../../services/UserService';
import { ZipService } from '../../services/ZipService';
import { getModelFromField, weekday } from '../../utils/MyLib';
import CollectMaterialModal from './CollectMaterialModal';

const Separator = styled.hr`
  border: 1px solid lightgray; /* Cor da linha branca */
`;

const P = styled.p`
  font-weight: 700;
`;

const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
`;

const SubCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  min-width: 80%;
  border-radius: var(--borderRadius-m);
  background: var(--bgColor-1);
  box-shadow: var(--shadow-1);
  margin-top: 8px;
`;
const CardsContent = styled.div`
  display: flex;
  width: 60px;
  height: 50px;
  padding: 11px 15px;
  background-color: var(--bgColor-1);
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius-p);
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  p {
    font-weight: 700;
  }
`;
interface IPossibleRoutes extends INeighborhoodSchedule {
  route_id: number;
  route_event_id: number;
  route_name: string;
  company: ICompany;
  days: string;
  weeksday: number[];
  dates: string[];
  packs: string;
  composition: IComposition[];
}
export default function CollectForm() {
  const [loading, setLoading] = useState(false);
  const [modalMaterial, setModalMaterial] = useState(false);
  const [modalAddress, setModalAddress] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [collect, setCollect] = useState<ICollect>(blankCollect);
  const [open, setOpen] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [allGenerators, setAllGenerators] = useState<IUser[]>([]);
  const [allRoutes, setAllRoutes] = useState<IRoute[]>([]);

  const [allPossibleRoutes, setAllPossibleRoutes] = useState<IPossibleRoutes[]>([]);
  const [allPossibleDates, setAllPossibleDates] = useState<any[]>([]);
  const [allCompositions, setAllCompositions] = useState<IComposition[]>([]);
  const [allPacks, setAllPacks] = useState<IPack[]>([]);
  const [colorStatus, setColorStatus] = useState('');
  const colors = collectStatus.map((status) => status.color);
  const [route_choiced, setRouteChoiced] = useState<number>(0);
  const [material, setMaterial] = useState<IMaterial>(blankMaterial);
  const [collectModalOperation, setCollectModalOperation] = useState('new');

  const confirmMessage = 'Tem certeza que deseja apagar a coleta?';
  let address = collect.address;
  const navigate = useNavigate();

  let { id } = useParams();
  let collectId = Number(id);

  async function fetchData() {
    setLoading(true);
    let allGenerators = await UserService.getGenerators();

    let allPacks = await PackService.getAll();
    setAllPacks(allPacks);

    let allRoutes = await RouteService.getAll();
    setAllRoutes(allRoutes);

    // let company = await protectForm('/collects');
    let company = getAuthUser().company || { id: null };
    let generator = blankUser;
    if (['Gerador', 'Gerador Comercial'].includes(getAuthUser().role.name)) {
      generator = getAuthUser();
    }

    let collect: ICollect = {
      ...blankCollect,
      generator: generator,
      generator_id: generator.id,
      materials: [],
      address: generator.address,
      recurrent: false,
      default_address: true,
      status: 3,
      company_id: company.id || 1,
      request_date: dayjs().format('YYYY-MM-DD')
    };
    if (collectId) {
      collect = await CollectService.getById(collectId);
    }
    if (['Admin', 'Operador', 'Catador'].includes(getAuthUser().role.name)) {
      allGenerators = allGenerators.filter((g) => (g.company_id && g.company_id === getAuthUser().company_id) || g.id === collect.generator_id);
    }
    if (['Gerador', 'Gerador Comercial'].includes(getAuthUser().role.name)) {
      allGenerators = allGenerators.filter((g) => (g.id && g.id === getAuthUser().id) || g.id === collect.generator_id);
    }
    setAllGenerators(allGenerators);
    if (collect.materials)
      collect.materials.map((m) => {
        m.container_id = m.container_id || 1;
        m.size = m.size || 'Pequena';
        m.quantity = m.quantity || 1;
        m.weight = m.weight || 0;
        m.unit_id = m.unit_id || 1;
        m.state_id = m.state_id || 1;
        m.treatment_id = m.treatment_id || 1;
        m.batch_number = m.batch_number || '';
      });

    setCollect(collect);
    setColorStatus(colors[collect.status]);
    setLoading(false);
  }
  function getRouteIndex() {
    let index = 0;
    if (collect.recurrent) index = allPossibleRoutes.findIndex((r) => r.route_id === collect.route_id);
    else index = allPossibleRoutes.findIndex((r) => r.route_id === collect.route_id && r.dates[0] === collect.dates[0]);
    setRouteChoiced(index);
    return index;
  }
  function getPacksNames(packs: number[]) {
    let packsNamesArray: Array<string> = [];
    packs?.forEach((pack_id) => {
      let pack_name = '***';
      let pack = allPacks.find((r) => r.id === pack_id);
      if (pack) {
        pack_name = pack.name;
      }
      packsNamesArray.push(pack_name);
    });
    let packString = packsNamesArray ? packsNamesArray.map((p) => p).join(', ') : '';
    return packString;
  }
  async function possibleDates() {
    let allPossibleDates: any[] = [];
    let route = allPossibleRoutes.find((r) => r.route_id === collect.route_id) || null;
    if (route) {
      if (collect.recurrent) {
        allPossibleDates = route.weeksday;
      } else {
        allPossibleDates = route.dates;
        // setCollect({ ...collect, dates: route.dates });
      }
    }
    setAllPossibleDates(allPossibleDates);
  }

  async function defaultComposition(): Promise<IComposition[]> {
    let defaultComposition: IComposition[] = [];
    let pack = await PackService.getById(1);
    if (pack) {
      defaultComposition = pack.composition;
    }
    return defaultComposition;
  }
  async function possibleCompositions() {
    let allCompositions: IComposition[] = [];
    let route = allPossibleRoutes[route_choiced];
    if (route) {
      allCompositions = route.composition;
    } else {
      allCompositions = await defaultComposition();
    }
    setAllCompositions(allCompositions);
  }

  function getComposition(packs: number[] | undefined) {
    let composition: IComposition[] = [];
    packs?.forEach((residue_id) => {
      let pack = allPacks.find((r) => r.id === residue_id);
      if (pack) {
        composition = [...composition, ...pack.composition];
      }
    });
    return composition;
  }
  async function possibleRoutes() {
    let allComp: IComposition[] = [];
    let allPR: IPossibleRoutes[] = [];
    allPR.push({
      route_id: 0,
      route_event_id: 0,
      route_name: '',
      company: blankCompany,
      days: '',
      packs: '',
      neighborhood_id: 0,
      neighborhood_name: '',
      initial_time: '',
      end_time: '',
      neighborhood_lat: '',
      neighborhood_lng: '',
      weeksday: [],
      dates: [],
      composition: await defaultComposition()
    });

    let filter = `city=${collect.address.city}&neighborhood=${collect.address.neighborhood}`;
    if (collect.recurrent) {
      let allRoutes = await RouteService.getAll(filter);
      allRoutes.forEach((r) => {
        let days = r.recurrent ? r.weekdays.map((d) => weekday[d]).join(', ') : r.dates.map((d) => d).join(', ');
        let packs = getPacksNames(r.packs);
        let s = r.schedule.find((s) => s.neighborhood_name === collect.address.neighborhood || s.neighborhood_name === 'Todos') || ({} as INeighborhoodSchedule);
        if (s.neighborhood_name === collect.address.neighborhood || s.neighborhood_name === 'Todos') {
          let n: IPossibleRoutes = {
            route_id: r.id,
            route_event_id: 0,
            route_name: r.name,
            company: r.company || getAuthUser().company,
            days: days,
            packs: packs,
            neighborhood_name: s.neighborhood_name,
            initial_time: s.initial_time,
            end_time: s.end_time,
            neighborhood_lat: s.neighborhood_lat,
            neighborhood_lng: s.neighborhood_lng,
            weeksday: r.weekdays,
            dates: [],
            composition: getComposition(r.packs)
          };
          allPR.push(n);
          n.composition.forEach((c) => {
            allComp.findIndex((comp) => comp.residue_id === c.residue_id) === -1 && allComp.push(c);
          });
        }
      });
    } else {
      let allRoutesEvents = await RouteEventsService.getAll(filter);
      allRoutesEvents = allRoutesEvents.filter((r) => r.date >= dayjs().format('YYYY-MM-DD') || [1, 2, 3, 4].includes(r.status));

      allRoutesEvents.forEach((r) => {
        let days = r.date;
        let packs = getPacksNames(r.packs);
        let s = r.schedule.find((s) => s.neighborhood_name === collect.address.neighborhood || s.neighborhood_name === 'Todos') || ({} as INeighborhoodSchedule);
        if (s.neighborhood_name === collect.address.neighborhood || s.neighborhood_name === 'Todos') {
          let n: IPossibleRoutes = {
            route_id: r.route_id,
            route_event_id: r.id,
            route_name: r.name,
            company: r.company || getAuthUser().company,
            days: days,
            packs: packs,
            neighborhood_name: s.neighborhood_name,
            initial_time: s.initial_time,
            end_time: s.end_time,
            neighborhood_lat: s.neighborhood_lat,
            neighborhood_lng: s.neighborhood_lng,
            weeksday: [],
            dates: [r.date],
            composition: getComposition(r.packs)
          };
          allPR.push(n);
          n.composition.forEach((c) => {
            allComp.findIndex((comp) => comp.residue_id === c.residue_id) === -1 && allComp.push(c);
          });
        }
      });
    }

    if (collect.recurrent)
      allPR.sort((a, b) => {
        return a.route_id - b.route_id;
      });
    else {
      allPR = allPR.filter((r) => r.dates[0] >= dayjs().format('YYYY-MM-DD') || r.dates[0] === collect.dates[0]);
      allPR.sort((a, b) => {
        if (a.dates[0] < b.dates[0]) return -1;
        else if (a.dates[0] > b.dates[0]) return 1;
        else return 0;
      });
    }
    setAllPossibleRoutes(allPR);
    getRouteIndex();
  }
  useEffect(() => {
    fetchData();
    setModalAddress(false);
    possibleCompositions();
  }, []);

  useEffect(() => {
    getRouteIndex();
  }, [allPossibleRoutes]);

  useEffect(() => {
    possibleRoutes();
  }, [collect.recurrent, collect.address]);

  useEffect(() => {
    possibleDates();
    possibleCompositions();
  }, [route_choiced, collect.recurrent]);

  function validateErrors(collect: ICollect) {
    let errors: any = {};
    if (!collect.generator_id || collect.generator_id === 0) errors.generator_id = 'Gerador obrigatório!';
    if (!collect.materials || collect.materials.length === 0) errors.materials = 'Escolha ao menos 1 material!';
    if (!collect.recurrent) {
      if (collect.route_id === 0 && collect.status !== 3) {
        //Sem rota e status diferente de "Sem area de atendimento"
        errors.status = 'Status invalido!';
        errors.route_choiced = 'Escolha uma rota!';
        toast.error('Coletas sem rotas devem ter o status "Sem área de atendimento" !');
      }
      // if (collect.route_id !== 0 && collect.status !== 1) {
      //   //Com rota e status diferente de "ativa"
      //   errors.status = 'Status invalido!';
      //   errors.route_choiced = 'Escolha uma rota!';
      //   toast.error('Coletas pontuais com rotas devem ter o status "Ativa" !');
      // }
    }
    // if (!validateRequired(collect.obs)) errors.obs = 'Observação obrigatória!';

    return errors;
  }

  async function handleBlurZipCode(e: any) {
    let zipCode = e.target.value;
    let address = blankAddress;
    if (zipCode[9] !== '_') {
      address = await ZipService.getAddress(zipCode);
      if (address.street) {
        setCollect({ ...collect, address: address });
      } else {
        toast.error('Cep não encontrado');
      }
    }
  }
  function handleWeekClick(i: number) {
    if (allPossibleDates.includes(i)) {
      collect.weekdays.find((d) => d === i) ? collect.weekdays.splice(collect.weekdays.indexOf(i), 1) : collect.weekdays.push(i);
      setCollect({ ...collect, weekdays: collect.weekdays });
    }
  }
  function handleNewMaterial() {
    setCollectModalOperation('new');
    setMaterial(blankMaterial);
    setModalMaterial(true);
  }

  function handleEditMaterial(id: number) {
    let mat = collect.materials.find((m) => m.residue_id === id);
    if (!mat) {
      return;
    }
    setCollectModalOperation('edit');
    setMaterial(mat);
    setModalMaterial(true);
  }
  function handleDeleteMaterial(id: number) {
    let materials = collect.materials;
    materials = collect.materials.filter((m) => m.residue_id !== id);
    setCollect({ ...collect, materials: materials });
  }

  function handleIncludeMaterial(m: IMaterial): void {
    let newMaterials = collect.materials;
    let material = collect.materials.find((mat) => mat.residue_id === m.residue_id);

    if (material && m.weight) {
      if (collectModalOperation === 'new') material.weight += Number(m.weight);
      else material.weight = Number(m.weight);
      material.container_id = m.container_id;
      material.quantity = m.quantity;
      material.size = m.size;
      material.unit_id = m.unit_id;
      material.state_id = m.state_id;
      material.treatment_id = m.treatment_id;
      material.batch_number = m.batch_number;
      material.residue_name = m.residue_name;
      material.container_name = m.container_name;
      material.unit_name = m.unit_name;
      material.unit_abreviation = m.unit_abreviation;
      material.state_name = m.state_name;
      material.treatment_name = m.treatment_name;
    } else {
      newMaterials.push(m);
    }
    setErrors({});
    setCollect({ ...collect, materials: newMaterials });
  }

  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    if (field === 'default_address') {
      value = value === 'true' ? true : false;
    }
    if (field === 'recurrent') {
      value = value === 'true' ? true : false;
    }
    switch (model) {
      case 'address':
        if (!collect.default_address) {
          address = { ...collect.address, [field]: value };
          setCollect({ ...collect, address: address });
        }
        break;
      default:
        switch (field) {
          case 'generator_id':
            let generator = allGenerators.find((g) => g.id === Number(value)) || null;
            if (generator) setCollect({ ...collect, generator_id: generator.id, generator: generator, address: generator.address });
            break;
          case 'route_choiced':
            setRouteChoiced(value);
            // if (collect.recurrent && value !== '0') dates = allPossibleDates;
            let status = 1;
            if (allPossibleRoutes[value].route_id === 0) status = 3;

            let route = allRoutes.find((r) => r.id === allPossibleRoutes[value].route_id) || blankRoute;
            if (collect.recurrent) {
              let route = allRoutes.find((r) => r.id === allPossibleRoutes[value].route_id) || blankRoute;
              setCollect({
                ...collect,
                status: status,
                route_id: allPossibleRoutes[value].route_id,
                company_id: allPossibleRoutes[value].company.id || 1,
                route: route,
                weekdays: [],
                materials: [],
                dates: []
              });
            } else {
              setCollect({
                ...collect,
                status: status,
                route_id: allPossibleRoutes[value].route_id,
                company_id: allPossibleRoutes[value].company.id || 1,
                route: route,
                dates: allPossibleRoutes[value].dates,
                materials: [],
                weekdays: []
              });
            }

            break;
          case 'recurrent':
            setRouteChoiced(0);
            setCollect({ ...collect, recurrent: value, route_id: 0, weekdays: [] });
            break;

          case 'default_address':
            let g = allGenerators.find((g) => g.id === collect.generator_id) || null;
            let gaddress = g?.address || blankAddress;
            if (value) {
              address = gaddress;
            } else {
              if (!collect.id) {
                //nova coleta
                address = gaddress;
              } else {
                //edicao de coleta
                if (collect.address.id === gaddress.id) address.id = 0;
              }
              setModalAddress(true);
            }
            setCollect({ ...collect, default_address: value, address: address });
            break;

          default:
            setCollect({ ...collect, [field]: value });
            break;
        }
    }
  }
  const handleSave = (e: any) => {
    e.preventDefault();
    const errors = validateErrors(collect);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveCollect(collect);
    }
  };

  function handleDeleteConfirmation() {
    setShowConfirmationModal(true);
  }

  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await CollectService.delete(collect.id)) {
      toast.success('Coleta apagada com sucesso');
      navigate(-1);
    }
  }

  async function saveCollect(collect: ICollect) {
    if (collect.id) {
      if (await CollectService.update(collect)) {
        toast.success('Coleta salva com sucesso');
        navigate(-1);
      }
    } else {
      let newCollect = await CollectService.create(collect);
      if (newCollect.id > 0) {
        collect.id = newCollect.id;
        toast.success('Coleta criada com sucesso');
        navigate(-1);
      }
    }
  }

  function getWeekdayBackGround(index: number) {
    let background = 'var(--bgColor-1)';
    if (collect.weekdays.includes(index)) background = 'var(--primary-700)';
    if (!allPossibleDates.includes(index)) background = 'lightgray';
    return background;
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={open} maxWidth="lg">
          <form onSubmit={(e) => e.preventDefault()}>
            <DialogTitle display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <RowTitle>REGISTRO DE COLETAS</RowTitle>
              <CloseModal
                onClick={() => {
                  navigate(-1);
                }}
              />
            </DialogTitle>

            <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
              <Grid container m={1}>
                <Grid item sm={12} md={6}>
                  <Stack direction="row" alignItems="center" spacing={2} padding={2}>
                    <Avatar
                      sx={{
                        width: '60px',
                        height: '60px',
                        border: '1px solid lightgray',
                        background: 'white',
                        boxShadow: 'var(--shadow-1)'
                      }}
                      alt="imagem"
                      src={collect.generator?.img ? baseImg + collect.generator.img : ''}
                    />

                    <FormControl fullWidth>
                      <InputLabel>Gerador</InputLabel>
                      <Select
                        key="collect.generator_id"
                        label="Gerador"
                        name="collect.generator_id"
                        value={collect.generator_id}
                        onChange={handleChange}
                        error={errors.generator_id}
                        placeholder="Gerador">
                        {allGenerators.map((g) => (
                          <MenuItem key={g.id} value={g.id}>
                            {g.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item sm={12} md={3}>
                  <Container>
                    Tipo de Coleta:
                    <RadioGroup key="collect.recurrent" name="collect.recurrent" row value={collect.recurrent} onChange={handleChange}>
                      <FormControlLabel key="rr01" value={true} label="Recorrente" control={<Radio />} />
                      <FormControlLabel key="rr02" value={false} label="Pontual" control={<Radio />} />
                    </RadioGroup>
                  </Container>
                </Grid>

                {/* <Grid item sm={12} md={2}>
                  <Container sx={{ margin: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="br">
                      <DatePicker format="DD/MM/YYYY" label="Data da requisição" value={dayjs(collect.request_date)} onChange={handleDate} />
                    </LocalizationProvider>
                  </Container>
                </Grid> */}
                <Grid item sm={12} md={3}>
                  <FormControl sx={{ minWidth: 200, maxWidth: 250, m: 1 }} margin="dense">
                    <InputLabel style={{ display: 'block', background: '#FFF', color: `${colorStatus}`, border: `2px solid ${colorStatus}`, borderRadius: '3px' }}>Status</InputLabel>
                    <Select
                      key="collect.status"
                      style={{ border: `2px solid ${colorStatus}`, color: `${colorStatus}`, borderRadius: '6px' }}
                      name="collect.status"
                      value={collect.status}
                      onChange={handleChange}
                      error={errors.status}>
                      {collectStatus.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Separator />
              <Grid container m={1}>
                <Grid item sm={12} md={6}>
                  <Container>
                    <FormControl sx={{ minWidth: 250, maxWidth: 550, m: 1 }} margin="dense">
                      <Typography variant="body1">Usar o endereço padrão:</Typography>
                      <RadioGroup key="collect.default_address" name="collect.default_address" row value={collect.default_address} onChange={handleChange}>
                        <FormControlLabel key="rr01" value={true} label="Sim" control={<Radio />} />
                        <FormControlLabel key="rr02" value={false} label="Não" control={<Radio />} />
                      </RadioGroup>
                    </FormControl>
                  </Container>
                </Grid>
                <Grid item sm={12} md={6}>
                  <div onClick={() => setModalAddress(true)}>
                    <AddressBox address={collect.address} />
                  </div>
                </Grid>
                <Dialog open={modalAddress} maxWidth="lg">
                  <DialogTitle textAlign="left">Endereço da coleta</DialogTitle>
                  <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
                    <Grid container>
                      <Grid item xs={12} md={1.5}>
                        <Stack>
                          <InputMask mask="99.999-999" name="address.zip_code" value={address.zip_code} onChange={handleChange} onBlur={handleBlurZipCode} maskChar="">
                            <SmallTextField label="CEP" error={errors.zip_code} helperText={errors.zip_code} sx={{ maxWidth: 200 }} />
                          </InputMask>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={3.5}>
                        <Stack>
                          <BigTextField key="address.street" label="Rua" name="address.street" value={address.street} onChange={handleChange} error={errors.street} helperText={errors.street} />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={1.2}>
                        <Stack>
                          <SmallTextField
                            key="address.number"
                            label="Número"
                            name="address.number"
                            value={address.number}
                            onChange={handleChange}
                            error={errors.number}
                            helperText={errors.number}
                            sx={{ maxWidth: 200 }}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={2.8}>
                        <Stack>
                          <BigTextField
                            key="address.complement"
                            label="Complemento"
                            name="address.complement"
                            value={address.complement}
                            onChange={handleChange}
                            error={errors.complement}
                            helperText={errors.complement}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Stack>
                          <MediumTextField
                            key="address.neighborhood"
                            label="Bairro"
                            name="address.neighborhood"
                            value={address.neighborhood}
                            onChange={handleChange}
                            error={errors.neighborhood}
                            helperText={errors.neighborhood}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <Stack>
                          <MediumTextField key="address.city" label="Cidade" name="address.city" value={address.city} onChange={handleChange} error={errors.city} helperText={errors.city} />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Stack>
                          <FormControl sx={{ maxWidth: 150, m: 1 }} margin="dense">
                            <InputLabel>Estado</InputLabel>
                            <Select key="address.state" label="Estado" name="address.state" value={address.state} onChange={handleChange} error={errors.address} placeholder="Estado">
                              {allStatesNames.map((state) => (
                                <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                  {state.abbreviation}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions sx={{ p: '1.25rem' }}>
                    <Button onClick={() => setModalAddress(false)}>Fechar</Button>
                    <Button color="secondary" onClick={() => setModalAddress(false)} variant="contained">
                      Salvar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>

              <Separator />
              <Grid container m={1}>
                <Grid item xs={12} md={6}>
                  <Stack m={1}>
                    <FormControl sx={{ minWidth: 400, maxWidth: 550, m: 0 }} margin="dense">
                      <InputLabel>Escolha da Rota</InputLabel>
                      <Select key="collect.route_id" label="Rota Escolhida" name="collect.route_choiced" value={route_choiced} onChange={handleChange} error={errors.route_choiced} placeholder="Rota">
                        {allPossibleRoutes.map((pr, index) => (
                          <MenuItem key={index} value={index}>
                            <div>
                              <p>
                                {pr.route_name}
                                {pr.company.fantasy_name ? ' - ' + pr.company.fantasy_name : ''}
                              </p>
                              <p>
                                <RecicleIcon /> {pr.packs}
                              </p>

                              <p>{pr.days + ' - ' + pr.initial_time + ' - ' + pr.end_time}</p>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                  {collect.recurrent && (
                    <Stack m={1}>
                      <FormControl sx={{ minWidth: 400, maxWidth: 550, m: 0 }} margin="dense">
                        <InputLabel>Frequencia</InputLabel>
                        <Select
                          key="collect"
                          label="Frequencia"
                          name="collect.weekly_frequence"
                          value={collect.weekly_frequence}
                          onChange={handleChange}
                          error={errors.weekly_frequence}
                          placeholder="Frequência">
                          <MenuItem value={1}>Semanal</MenuItem>
                          <MenuItem value={2}>Quinzenal</MenuItem>
                          <MenuItem value={4}>Mensal</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  )}

                  <Stack m={1}>
                    <div className="week-date-container">
                      {collect.recurrent ? (
                        <CardsModalWeek>
                          {weekday.map((day, index) => (
                            <CardsContent
                              key={'cc' + index}
                              onClick={() => handleWeekClick(index)}
                              style={{
                                backgroundColor: getWeekdayBackGround(index),
                                color: collect.weekdays.includes(index) ? 'var(--black-0)' : 'var(--black-600)'
                              }}>
                              <p>{day}</p>
                            </CardsContent>
                          ))}
                        </CardsModalWeek>
                      ) : (
                        collect.dates.map((s) => <p> {s}</p>)
                      )}
                    </div>
                  </Stack>
                  <Stack m={1}>
                    <TextField
                      key="collect.obs"
                      label="Observação"
                      name="collect.obs"
                      value={collect.obs}
                      onChange={handleChange}
                      error={errors.obs}
                      helperText={errors.obs}
                      sx={{ width: '550px' }}
                      multiline={true}
                      rows={3}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardsContainerModal height="175px" title="Materiais" openModal={() => handleNewMaterial()} plusIcon="true" error={errors.materials}>
                    {collect.materials.map((m, index) => (
                      <SubCardComponent
                        key={m.residue_id}
                        type={['delete']}
                        id={m.residue_id}
                        content={' ' + m.residue_name + ' - (' + m.quantity + ') ' + m.container_name + ' ' + m.size}
                        value={m}
                        format={2}
                        onDelete={handleDeleteMaterial}
                        onClick={handleEditMaterial}
                      />
                    ))}
                  </CardsContainerModal>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
              <Button
                color="warning"
                disabled={collect.id === 0}
                onClick={() => {
                  handleDeleteConfirmation();
                }}>
                Apagar
              </Button>

              <Button
                onClick={() => {
                  navigate(-1);
                }}>
                Cancelar
              </Button>
              <Button color="secondary" onClick={handleSave} variant="contained">
                Salvar
              </Button>
            </DialogActions>
            <CollectMaterialModal initialValue={material} allCompositions={allCompositions} open={modalMaterial} onClose={() => setModalMaterial(false)} onSubmit={handleIncludeMaterial} />
            <ConfirmModal title="Deleção de Evento de Rota " message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
          </form>
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
