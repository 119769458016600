import api from './api';
import { ICompany, blankCompany, companyProps } from '../models/Company';
import { mapNullToString, clearObj, capitalizeObj } from '../utils/MyLib';
import { getAuthUser } from './auth';
import { toast } from 'react-toastify';
import { blankAddress } from '../models/Address';

export class CompanyService {
  static async getAll(): Promise<ICompany[]> {
    try {
      const response = await api.get('/companies');
      let allCompany = response.data as ICompany[];
      // let role = getAuthUser().role;
      // if (role.name === 'Admin' || role.name === 'Operador') {
      //   allCompany = allCompany.filter((c) => c.company_id === getAuthUser().company.id || c.id === getAuthUser().company.id);
      // }
      return allCompany;
    } catch (error: any) {
      if (error.response.status === 401) {
        window.location.href = '/login';
      }
      toast.error('Erro ao consultar as empresas! ');

      return [];
    }
  }
  static async getAllNames(): Promise<any[]> {
    try {
      const response = await api.get('/companies/names');
      let allCompany = response.data as ICompany[];
      return allCompany;
    } catch (error: any) {
      toast.error('Erro ao consultar as empresas! ');
      return [];
    }
  }
  static async getById(id: number): Promise<ICompany> {
    try {
      const response = await api.get(`/companies/${id}`);
      let c = mapNullToString(response.data);
      clearObj(c, companyProps);
      return c;
    } catch (error: any) {
      toast.error('Erro ao consultar empresa');

      return blankCompany;
    }
  }

  static async getPermissionsCompanies(): Promise<ICompany[]> {
    // let authUser = getAuthUser();
    let companies: ICompany[] = [];
    try {
      // if (authUser.role.name === 'Super Admin') {
      companies = (await CompanyService.getAll()) as ICompany[];
      // } else if (authUser.company) {
      //   companies = [authUser.company];
      // } else {
      //   companies = [];
      // }
    } catch (error) {
      toast.error('Erro ao carregar companhias permitidas para o Usuário');
    }
    return companies;
  }

  static async getAllColetadoras(): Promise<ICompany[]> {
    // let authUser = getAuthUser();
    let companies: ICompany[] = [];
    try {
      // if (authUser.role.name === 'Super Admin') {
      companies = (await CompanyService.getAll()) as ICompany[];
      // } else if (authUser.company) {
      //   companies = [authUser.company];
      // } else {
      //   companies = [];
      // }
      companies = companies.filter((c) => c.type.includes('Coletadora'));
    } catch (error) {
      toast.error('Erro ao carregar companhias permitidas para o Usuário');
    }
    return companies;
  }
  static async getAddress(id: number) {
    let c = await CompanyService.getById(id);
    if (c) {
      return c.address;
    }
    return blankAddress;
  }
  static async create({ id, ...company }: ICompany): Promise<ICompany> {
    try {
      let c = company;
      c = this.standarlizeCompany(c);
      const response = await api.post('/companies', c);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir Empresa');
        return blankCompany;
      }
      toast.success('Empresa incluída com sucesso!');
      return response.data as ICompany;
    } catch (error: any) {
      toast.error('Erro ao Incluir Empresa');
      return blankCompany;
    }
  }
  static async update({ ...company }: ICompany): Promise<boolean> {
    try {
      let c = company;
      this.standarlizeCompany(c);
      const response = await api.put(`/companies/${c.id}`, c);
      if (response.status !== 200) {
        toast.error('Erro ao atualizar Empresa');
        return false;
      }
      toast.success('Empresa atualizada com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao atualizar empresa');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/companies/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao excluir empresas!');
        return false;
      }
      toast.success('Empresa excluída com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao excluir empresas!');
      return false;
    }
  }

  static standarlizeCompany(c: ICompany | any): ICompany {
    if (c.company_id === '0') {
      c.company_id = null;
    }
    if (!c.company_id) {
      c.company_id = null;
    }
    if (!c.address.id) {
      c.address.id = undefined;
    }
    if (c.admin_id === '0' || c.admin_id === 0) {
      c.admin_id = undefined;
    }
    if (c.admin.id === '0' || c.admin.id === 0) {
      c.admin = undefined;
    }
    if (!c.licenses) {
      c.licenses = [];
    }
    return capitalizeObj(c);
  }
}

export default CompanyService;
